import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { campaignDetailsDataDefs } from "modules/Campaigns/constants/dataDefs";

import { CreateCampaignFields } from "./CreateCampaignFields";

/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  // TODO: unique validation messages
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.status) {
    errors.status = "Required";
  }
  if (!values.brand_id) {
    errors.brand_id = "Required";
  }
  if (values?.enableAccountSegmentation) {
    if (!values.department) {
      errors.department = "Required";
    }
    if (!values.shortId) {
      errors.shortId = "Required";
    }
  }
  return errors;
};

// Get the field definitions for the campaign details form.
const fieldDefs = campaignDetailsDataDefs({})?.body;

/**
 * @typedef {Object} CreateCampaignModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} [initialCampaignName] - Initial campaign name
 * @property {object} [initialBrand_id] - Initial brand ID
 */
/**
 * Create Campaign Modal
 * @param {CreateCampaignModalProps} props
 * @returns {JSX.Element}
 */
export const CreateCampaignModal = ({
  modalOpen,
  toggleModal,
  initialCampaignName = undefined,
  initialBrand_id,
  ...props
}) => {
  const navigate = useNavigate();
  const [createCampaign, { isLoading }] = apiSlice.useCreateCampaignMutation();

  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      try {
        const newCampaign = await createCampaign(values).unwrap();
        toggleModal();
        navigate(`/campaigns/${newCampaign?._id}`);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [createCampaign, navigate, toggleModal],
  );

  const initialValues = getInitialValues(fieldDefs, {
    name: initialCampaignName,
    brand_id: initialBrand_id,
  });

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {isLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Create Campaign</ModalHeader>
          <ModalBody>
            <CreateCampaignFields
              fieldDefs={fieldDefs}
              initialValues={initialValues}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

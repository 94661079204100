import { Outlet } from "react-router-dom";

import { BrandsGrid } from "features/brands/components";

export function BrandsListPage() {
  return (
    <section className="brands-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Brands List</h1>
        </div>
        <div className="main-page__body">
          <BrandsGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
}

import { useFormikContext } from "formik";

import { useConfirmNavigate } from "hooks/useConfirmNavigate";

/**
 * A custom hook that prompts the user with a confirmation message before navigation if the form is dirty. Requires formik context.
 * @returns {void}
 */
const useUnsavedChangesPrompt = () => {
  const { dirty } = useFormikContext();

  useConfirmNavigate(
    dirty,
    "You have unsaved changes! Are you sure you want to leave?",
  );
};

export default useUnsavedChangesPrompt;

// Similar to BooleanBadge, but display no icon for falsy, and display warning icon for truthy
// Meant for things like "userDisabled"
import { Badge } from "reactstrap";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const WarningBoolIndicator = ({
  value,
  falsyText = "",
  truthyText = "",
  type = "badge",
  ...props
}) => {
  if (!value) {
    return <span>{falsyText}</span>;
  }
  return (
    <>
      {type === "icon" && (
        <div className="d-flex align-items-center">
          <ExclamationTriangleIcon
            height={20}
            width={20}
            strokeWidth={2.5}
            className="text-warning"
          />
          {truthyText && <span className="ms-2">{truthyText}</span>}
        </div>
      )}
      {type === "badge" && (
        <Badge color="warning" pill {...props}>
          <ExclamationTriangleIcon height={20} width={20} strokeWidth={2.5} />
          {truthyText && <span className="ms-2">{truthyText}</span>}
        </Badge>
      )}
    </>
  );
};

export default WarningBoolIndicator;

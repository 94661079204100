/**
 * Filters out column definitions based on DataSources.
 *
 * @param {Object} params
 * @param {Array} params.columnDefs - The array of column definitions to filter.
 * @param {Array} [params.excludeDataSources] - The array of data sources to exclude. Defaults to an empty array.
 * @returns {Array} - The filtered column definitions.
 */
export const filterColDefs = ({ columnDefs, excludeDataSources = [] }) => {
  const filterColumns = (colDef) => {
    const dataSources = colDef?.dataSource?.split(",")?.map((id) => id?.trim());
    if (dataSources?.some((id) => excludeDataSources?.includes(id))) {
      return null;
    }
    if (colDef.children) {
      const filteredChildren = colDef.children
        .map(filterColumns) // Recursively filter children
        .filter(Boolean); // Remove any null or undefined values

      return filteredChildren.length > 0
        ? { ...colDef, children: filteredChildren }
        : null; // If no children left, filter out the parent as well
    }

    return colDef;
  };

  return columnDefs.map(filterColumns).filter(Boolean);
};

import { createElement } from "react";

import clsx from "clsx";

import { useGetAssignmentsQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { assignmentsColDefs } from "features/assignments/constants/assignmentsColDefs";

const CreateAssignmentInstructions = () => {
  return (
    <p className="text-muted small mx-auto my-0" style={{ maxWidth: "24em" }}>
      To create a new assignment go to a Campaign page, then Creator Assignments
      tab.
    </p>
  );
};

/**
 * Renders grid of assignments with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.gridId]
 * @param {React.ReactElement} [props.HeaderActionElem]
 * @param {string} [props.excludeDataSources] - Comma separated string of dataSources. Column definitions with matching data sources (DataSources) will be excluded from the grid definition.
 * @param {Object} [props.filterParams]
 * @param {number} [props.filterParams.skip=0]
 * @param {number} [props.filterParams.limit=0]
 * @param {string} [props.filterParams.sortKey]
 * @param {number} [props.filterParams.sortValue]
 * @param {string} [props.filterParams.createdFrom]
 * @param {string} [props.filterParams.createdTo]
 * @param {string} [props.filterParams.campaign_id]
 * @param {string} [props.filterParams.user_id]
 * @param {string} [props.filterParams.brand_id]
 * @param {boolean} [props.filterParams.onlyOverdue]
 * @param {boolean} [props.filterParams.onlyUpcoming]
 * @param {boolean} [props.filterParams.sortByDeliverablesDue]
 * @param {boolean} [props.filterParams.creatorActionRequired]
 * @param {boolean} [props.filterParams.allowContentApproval]
 * @param {string} [props.filterParams.campaignInvitationStatus]
 * @param {boolean} [props.filterParams.completedCampaignBrief]
 * @param {boolean} [props.filterParams.hasMilestones]
 * @param {boolean} [props.filterParams.hasOverdueMilestones]
 * @param {boolean} [props.filterParams.onlyCompleteMilestones]
 * @param {boolean} [props.filterParams.hasUpcomingMilestones]
 * @param {boolean} [props.filterParams.hasMilestonePaymentsDue]
 * @param {boolean} [props.filterParams.sortByAgreementActionRequired]
 * @param {boolean} [props.filterParams.onlyUpcomingMilestonesAndAssignments]
 * @param {boolean} [props.filterParams.onlyUpcomingMilestonesAndAssignments]
 * @param {string} [props.filterParams.status] - Comma-separated string. Enum: ???
 * @param {string} [props.filterParams.lemon8ContentManager] - No longer used?
 */
export const AssignmentsGrid = ({
  filterParams = {},
  className,
  gridId = "",
  excludeDataSources = "",
  ...restProps
}) => {
  const { data, isFetching, refetch } = useGetAssignmentsQuery(filterParams);
  const classes = clsx("assignments-grid", "h-100", className);

  const id = (gridId += ".assignments");

  return (
    <div className={classes}>
      <DataGrid
        gridId={id}
        columnDefs={assignmentsColDefs}
        excludeDataSources={excludeDataSources}
        appliedFilters={true}
        borders={true}
        height="100%"
        idField="_id"
        loading={isFetching}
        autoSizeIgnoreHeaders={false}
        quickFilter={true}
        rowData={data?.results ?? []}
        showLinkButton={true}
        linkButtonTo="/assignments/:_id"
        refetch={refetch}
        HeaderActionElem={createElement(CreateAssignmentInstructions)}
        {...restProps}
      />
    </div>
  );
};

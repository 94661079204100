import { useParams } from "react-router-dom";

import { campaignTabOptions } from "features/campaigns/constants/tabOptions";

export const CampaignAssignmentSection = () => {
  const { assignmentsTab } = useParams();
  const Subview = campaignTabOptions.find(
    (tab) => tab.id === assignmentsTab,
  )?.Subview;
  if (!Subview) {
    return;
  }

  return (
    // TODO @segmentation error boundary
    <div className="campaign-assignment-section flex-fill">
      <Subview />
    </div>
  );
};

import clsx from "clsx";

import { useGetBrandsQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { brandsColDefs } from "features/brands/constants/brandsColDefs";
import CreateBrandModalButton from "modules/Brands/components/CreateBrandModalButton";

// ! api slice currently accepts no filterParams
export const BrandsGrid = ({ className = "", gridId = "" }) => {
  const { data, isFetching, refetch } = useGetBrandsQuery();
  const classes = clsx("brands-grid", "h-100", className);
  const id = (gridId += ".brands");

  return (
    <>
      <div className={classes}>
        <DataGrid
          gridId={id}
          idField="_id"
          loading={isFetching}
          refetch={refetch}
          rowData={data?.results ?? []}
          columnDefs={brandsColDefs}
          appliedFilters={true}
          quickFilter={true}
          borders
          height="100%"
          showLinkButton={true}
          linkButtonTo="/brands/:_id"
          HeaderActionElem={<CreateBrandModalButton />}
        />
      </div>
    </>
  );
};

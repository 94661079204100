import { useCallback, useMemo } from "react";

import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import { showToast } from "components/Toasts/helpers/showToast";
import { assignmentDataDefs } from "features/assignments/constants/assignmentDataDefs";
import { getChangedValues } from "helpers/getChangedValues";

// import "./index.scss";

export const Assignment = ({
  assignment_id,
  isEditing,
  setIsEditing,
  disableEditing,
  ...props
}) => {
  const { data } = apiSlice.useGetAssignmentQuery({
    _id: assignment_id,
  });
  const [updateAssignment] = apiSlice.useUpdateAssignmentMutation();
  const assignmentDefs = useMemo(() => assignmentDataDefs(data), [data]);
  const initialValues = useMemo(
    () => getInitialValues(assignmentDefs?.body, data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignmentDefs?.body, data, isEditing],
  );
  const validate = useCallback((_values) => {
    const errors = {};
    return errors;
  }, []);
  const onSubmit = useCallback(
    async(values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        if (Object.keys(changedValues).length) {
          await updateAssignment({
            _id: data?._id,
            ...changedValues,
          }).unwrap();
        }
        showToast({
          type: "success",
          message: "Assignment saved successfully.",
        });
        setIsEditing(false);
      } catch (error) {
        showToast({
          type: "error",
          message: `Error saving assignment. ${error?.data?.message || error?.message}`,
        });
        console.error("Error saving assignment", error);
      }
      actions.setSubmitting(false);
    },
    [data?._id, initialValues, setIsEditing, updateAssignment],
  );
  return (
    <div className="assignment-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true}>
                {assignmentDefs?.body?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <h2 className="fs-5 section-label text-muted">
                            {def?.label}
                          </h2>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={data}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

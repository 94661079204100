import { Outlet, useParams } from "react-router-dom";
import { Card, CardHeader } from "reactstrap";

import { NavTabs, TabContent } from "components/NavTabs";
import { campaignTabOptions } from "features/campaigns/constants/tabOptions";

export function CampaignAssignmentsPageSection() {
  const { assignmentsTab, campaign_id } = useParams();
  return (
    <div className="campaign-assignments-page-section d-flex flex-fill position-relative">
      <Card className="border-0 rounded-0 flex-fill" outline body={false}>
        <CardHeader>
          <NavTabs tabs={campaignTabOptions} />
        </CardHeader>
        <TabContent
          className="p-2"
          activeTabId={assignmentsTab}
          tabs={campaignTabOptions}
          campaign_id={campaign_id}
        />
      </Card>
      <Outlet />
    </div>
  );
}

import { Outlet } from "react-router-dom";

import { TransfersGrid } from "features/transfers/components";

export function TransfersListPage() {
  return (
    <section className="transfers-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Transfers List</h1>
        </div>
        <div className="main-page__body">
          <TransfersGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
}

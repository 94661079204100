import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import Loader from "components/Loader";
import { currencyFormatter } from "helpers/formatters";

const StripeBalanceCard = ({
  // loading,
  // stripeBalancePending,
  // stripeBalanceAvailable,
  // stripeBalanceTotal,
  // pendingTopups,
  // pastTopups,
  ...props
}) => {
  const { data, isLoading } = apiSlice.useGetStripeBalancesQuery();
  const stripeBalances = data?.result;
  return (
    <Card>
      <CardBody>
        {isLoading && <Loader />}
        <CardTitle>
          <h2 className="fs-4">NeoReach Platform Balances</h2>
        </CardTitle>
        <CardText className="dashboard__content__labelled-data">
          <span>
            <span className="small text-muted mb-2">Available</span>
            {currencyFormatter(stripeBalances?.availableBalance)}
          </span>
          <span>
            <span className="small text-muted mb-2">Pending</span>
            {currencyFormatter(stripeBalances?.pendingBalance)}
          </span>
          <span>
            <span className="small text-muted mb-2">Total</span>
            {currencyFormatter(stripeBalances?.totalBalance)}
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default StripeBalanceCard;

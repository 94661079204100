// @ts-nocheck
import { createRequestActionTypes } from "../../../actions";
import WarningBoolCellRenderer from "../../../components/DataGrid/customRenderers/WarningBoolCellRenderer";

export const GET_CONTENTS_ERROR = "Error reading ContentQueue";
export const GET_POSTS_ERROR = "Error reading Posts";

export const READ_MANY_POSTS_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaignContent`;

export const contentQueueActionTypes = {
  getContents: createRequestActionTypes("GET_CONTENTS"),
  updateContents: createRequestActionTypes("UPDATE_CONTENTS"),
  changeDisplayedPost: createRequestActionTypes("CHANGE_DISPLAYED_POST"),
  changeDisplayedPostIsDirty: createRequestActionTypes(
    "CHANGE_DISPLAYED_POST_IS_DIRTY",
  ),
};

export const contentStatusDict = {
  draft: {
    label: "Draft",
    labelVerb: "Move to Drafts",
    color: "secondary",
    // allowedTransitions: ["awaiting approval"],
    allowedTransitions: [],
    actionRequired: false,
  },
  "awaiting approval": {
    label: "Awaiting Approval",
    labelVerb: "Submit for Approval",
    color: "warning",
    allowedTransitions: ["rejected", "approved"],
    actionRequired: true,
  },
  rejected: {
    label: "Rejected",
    labelVerb: "Reject",
    color: "danger",
    // allowedTransitions: ["awaiting approval"],
    allowedTransitions: [],
    actionRequired: false,
  },
  approved: {
    label: "Approved",
    labelVerb: "Approve",
    color: "success",
    // allowedTransitions: ["awaiting link approval"],
    allowedTransitions: [],
    actionRequired: false,
  },
  "awaiting link approval": {
    label: "Awaiting Link Approval",
    labelVerb: "Submit for Link Approval",
    color: "warning",
    allowedTransitions: ["link rejected", "complete"],
    actionRequired: true,
  },
  "link rejected": {
    label: "Link Rejected",
    labelVerb: "Reject Link",
    color: "danger",
    // allowedTransitions: ["awaiting link approval"],
    allowedTransitions: [],
    actionRequired: false,
  },
  complete: {
    label: "Complete",
    labelVerb: "Mark Complete",
    color: "success",
    allowedTransitions: [],
    actionRequired: false,
  },
};

export const contentCategoryDict = {
  fashion: {
    label: "Fashion",
  },
  travel: {
    label: "Travel",
  },
  beauty: {
    label: "Beauty",
  },
  wellnessfitness: {
    label: "Wellness/Fitness",
  },
  food: {
    label: "Food",
  },
  home: {
    label: "Home",
  },
  productivity: {
    label: "Productivity",
  },
  reading: {
    label: "Reading",
  },
};

export const contentColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  { headerName: "ID", field: "short_id" },
  {
    headerName: "Action Required",
    sort: "desc", // initial sort order on load
    type: "boolean",
    cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
    valueGetter: (params) => {
      const status = params.data?.status;
      return contentStatusDict[status]?.actionRequired;
    },
    cellRenderer: WarningBoolCellRenderer,
  },
  { headerName: "Status", field: "status" }, // TODO: custom cell renderer to display colored badge
  {
    headerName: "Creator Name",
    field: "user_id.name",
    type: "text",
  },
  {
    headerName: "Campaign",
    field: "campaign_id.name",
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    headerName: "Lemon8 Username",
    field: "lemon8Id",
    type: "text",
  },
  {
    headerName: "Category",
    field: "category",
    type: "text",
    valueGetter: (params) => {
      const category = params.data?.category;
      return contentCategoryDict[category]?.label;
    },
  },
  {
    headerName: "Title",
    field: "title",
    type: "text",
  },
  {
    headerName: "Feedback Date",
    type: "date",
    valueGetter: (params) => {
      const sortedFeedbacks = params.data?.feedback?.sort(
        (a, b) => new Date(b.created) - new Date(a.created),
      );
      const getted = new Date(sortedFeedbacks[0]?.created);
      // handle invalid dates
      return getted instanceof Date && !isNaN(getted) ? getted : null;
    },
  },
  {
    headerName: "Recent Feedback",
    minWidth: 600,
    filterParams: {
      // TODO: tooltips for truncated values
      showTooltips: true,
    },
    valueGetter: (params) => {
      const sortedFeedbacks = params.data?.feedback?.sort(
        (a, b) => new Date(b.created) - new Date(a.created),
      );
      const recentFeedback = sortedFeedbacks[0];
      const truncated
        = recentFeedback?.message?.length > 100
          ? recentFeedback?.message?.substring(0, 100) + "..."
          : recentFeedback?.message;
      return truncated;
    },
  },
];

import { useCallback, useState } from "react";

import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import CampaignTopupModal from "./CampaignTopupModal";

const CampaignTopupModalButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="create-campaign-topup">
      <Button
        color="dark"
        className="d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Campaign Topup
      </Button>
      <CampaignTopupModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      ></CampaignTopupModal>
    </div>
  );
};

export default CampaignTopupModalButton;

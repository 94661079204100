import { useEffect } from "react";

import { connect } from "react-redux";

import Loader from "components/Loader";

import { logout } from "./actions";

const Logout = ({ logout, ...props }) => {
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div>
      <Loader></Loader>
    </div>
  );
};

const mapStateToProps = (_state) => {
  return {};
};

export default connect(mapStateToProps, {
  logout,
})(Logout);

import AssignmentsDue from "../components/MilestonesCard/AssignmentsDue";
import AssignmentsUpcoming from "../components/MilestonesCard/AssignmentsUpcoming";
import MilestonesDue from "../components/MilestonesCard/MilestonesDue";
import MilestonesUpcoming from "../components/MilestonesCard/MilestonesUpcoming";
import PaymentForecast from "../components/MilestonesCard/PaymentForecast";

export const milestonesCardTabOptions = [
  {
    label: "Upcoming Payments",
    id: "upcoming-milestones",
    PaneComponent: MilestonesUpcoming,
  },
  {
    label: "Payments Due",
    id: "due-milestones",
    PaneComponent: MilestonesDue,
  },
  {
    label: "Upcoming Assignments",
    id: "upcoming-assignments",
    PaneComponent: AssignmentsUpcoming,
  },
  {
    label: "Assignments Due",
    id: "due-assignments",
    PaneComponent: AssignmentsDue,
  },
  {
    label: "Payment Forecast",
    id: "payment-forecast",
    PaneComponent: PaymentForecast,
  },
];

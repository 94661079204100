import { useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { Button, Label } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { Campaign } from "features/campaigns/components/Campaign";

export function CampaignPage() {
  const { campaign_id } = useParams();
  const { data } = apiSlice.useGetCampaignQuery({ _id: campaign_id });
  const displayedCampaign = data?.result;

  const [isEditing, setIsEditing] = useState(false);

  const enableEditing = () => {
    setIsEditing(true);
  };

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div className="campaign-page">
      <Panel key={campaign_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/campaigns"} className="my-1">
          <h1 className="fs-2 d-flex align-items-center">
            <Label className="text-muted small">Campaign:</Label>
            {displayedCampaign?.name}
            {!isEditing && (
              <Button
                color="secondary"
                outline
                size="sm"
                className="d-flex align-items-center ms-3"
                onClick={enableEditing}
              >
                <PencilIcon height={20} width={20} />
              </Button>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split="vertical">
          <Campaign
            campaign_id={campaign_id}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <Outlet />
        </PanelBody>
      </Panel>
    </div>
  );
}

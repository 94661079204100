import { CampaignSelect } from "modules/Brands/components/CampaignSelect";

export const platformTopupDataDefs = [
  {
    label: "Description",
    fieldKey: "description",
    type: "string",
    editing: { required: true },
  },
  {
    label: "Amount",
    fieldKey: "amount",
    type: "currency",
    editing: {
      required: true,
    },
  },
];
//     | Parameter Name         | Type       | Default | Required | Description |
// | :--------------------- | :--------- | :------ | :------- | :---------- |
// | `campaign_id`          | `ObjectId` |         | `true`   |             |
// | `description`          | `String`   |         | `true`   |             |
// | `amount`               | `Number`   |         | `true`   |             |
// | `invoiceId`            | `String`   |         | `true`   |             |
// | `transactionId`        | `String`   |         | `false`  |             |
export const campaignTopupDataDefs = [
  {
    label: "Campaign",
    fieldKey: "campaign_id",
    // displayValue: displayedCampaign?.brand_id?.name,
    type: "string",
    editing: {
      required: true,
      fieldKey: "campaign_id",
      CustomRenderer: CampaignSelect,
    },
  },
  {
    label: "Description",
    fieldKey: "description",
    type: "string",
    editing: { required: true },
  },
  {
    label: "Amount",
    fieldKey: "amount",
    type: "currency",
    editing: {
      required: true,
    },
  },
  {
    label: "Invoice ID",
    fieldKey: "invoiceId",
    type: "string",
    editing: { required: true },
  },
  {
    label: "Transaction ID",
    fieldKey: "transactionId",
    type: "string",
    editing: { required: false },
  },
];

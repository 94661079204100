import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink as RSNavLink } from "reactstrap";

/**
 * @typedef {Object} NavLinkProps
 * @property {React.ReactNode} children - The content to be rendered inside the NavLink component.
 * @property {Object} [props] - Additional properties passed to the NavLink component.
 */

/**
 * Renders a navigation link component with functionality of react-router NavLink, and styled with reactstrap NavLink.
 *
 * @component
 * @param {NavLinkProps & import('react-router-dom').NavLinkProps & import('reactstrap').NavLinkProps} props
 * @returns {JSX.Element} The rendered NavLink component.
 */
export const NavLink = ({ children, ...props }) => {
  return (
    <RSNavLink {...props} tag={RRNavLink}>
      {children}
    </RSNavLink>
  );
};

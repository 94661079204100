import { useMemo } from "react";

import { createSelector } from "@reduxjs/toolkit";

import { useGetAssignmentsQuery } from "app/apiSlice";
import MilestonesList from "features/milestones/MilestonesList";
import { milestonesDueColDefs } from "modules/Dashboard/constants/colDefs";
import { processDueMilestones } from "modules/Dashboard/helpers/processDueAndUpcoming";

const MilestonesDue = ({ activeTabId, tabId, ...props }) => {
  const selectMilestonesDue = useMemo(() => {
    const emptyArray = [];
    // Return a unique selector instance for this page so that
    // the filtered results are correctly memoized
    return createSelector(
      (res) => res.data,
      (data) => {
        // @ts-ignore
        return data ? processDueMilestones(data?.results) : emptyArray;
      },
    );
  }, []);

  const { milestonesDue, isLoading, error } = useGetAssignmentsQuery(
    {
      calculate:
        "assignmentBalances contentCounts deliverableMetrics agreementMetrics",
      populate: "user_id campaign_id",
      hasMilestonePaymentsDue: true,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        milestonesDue: selectMilestonesDue(result),
      }),
    },
  );

  if (error) {
    console.error("Error fetching due milestones", error);
    return;
  }

  return (
    <MilestonesList
      className="milestones-due"
      rowData={milestonesDue}
      visible={activeTabId === tabId}
      gridId="milestones-due"
      borders
      autoSizeEnabled={true}
      autoSizeIgnoreHeaders={true}
      idField="_id"
      columnDefs={milestonesDueColDefs}
      loading={isLoading}
      quickFilter={true}
      appliedFilters={true}
    />
  );
};

export default MilestonesDue;

import { useMemo } from "react";

import clsx from "clsx";

import { TransfersGrid } from "features/transfers";

export const CreatorTransfers = ({ creator_id, className, ...props }) => {
  const classes = clsx("creator-transfers", className);
  const filterParams = useMemo(() => {
    return {
      user_id: creator_id,
    };
  }, [creator_id]);
  return (
    <TransfersGrid
      filterParams={filterParams}
      className={classes}
      gridId="creator-transfers"
    />
  );
};

import { memo } from "react";

import {
  AssignmentLinkCellRenderer,
  CampaignLinkCellRenderer,
} from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";
import { CreatorLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/CreatorLinkCellRenderer";

export const transfersColDefs = [
  {
    headerName: "id",
    field: "id",
    hide: true,
  },
  {
    headerName: "Type",
    field: "type",
    type: "text",
    valueFormatter: ({ value }) => {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  {
    headerName: "Amount",
    field: "transfer_amount",
    type: "currency",
  },
  {
    headerName: "Reversed Amount",
    field: "amount_reversed",
    type: "currency",
  },
  {
    headerName: "Reversal Reason",
    type: "text",
    field: "reverse_reason",
  },
  {
    headerName: "Currency",
    field: "currency",
    // TODO: @formatter currency abbreviation formatter
    type: "text",
    hide: true,
  },
  {
    // TODO: @segmentation link to milestone
    headerName: "Milestone",
    field: "milestone_id.name",
    type: "text",
    dataSource: "milestone",
  },
  {
    headerName: "Assignment",
    field: "assignment_id",
    dataSource: "assignment",
    cellRenderer: memo(AssignmentLinkCellRenderer),
    type: "text",
    initialWidth: 200,
    filterValueGetter: (params) => {
      // Makes the assignment's creator name, campaign name, and assignment ID searchable
      if (!params.data?.user_id?.name || !params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.campaign_id?.name} ${params.data?.assignment_id}`;
    },
  },
  {
    headerName: "Creator",
    field: "user_id._id",
    dataSource: "creator",
    cellRenderer: memo(CreatorLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the creator's name and ID searchable
      if (!params.data?.user_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.user_id?._id}`;
    },
  },
  {
    headerName: "Campaign",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    filterValueGetter: (params) => {
      // Makes the campaign's name and ID searchable
      if (!params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.campaign_id?.name} ${params.data?.campaign_id?._id}`;
    },
  },
  {
    headerName: "Campaign Short ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "shortId",
    },
    filterValueGetter: (params) => {
      return params.data?.shortId;
    },
    // TODO: @segmentation ask matt to add populate for shortId on transfers campaign populate
  },
  {
    headerName: "Department",
    field: "campaign_id.department",
    dataSource: "campaign",
    type: "text",
    valueGetter: (params) => {
      let text = "";
      if (params.data?.campaign_id?.department === "managed") {
        text = "Managed Services";
      }
      if (params.data?.campaign_id?.department === "talent") {
        text = "Talent";
      }
      return text;
    },
    // TODO: @segmentation ask matt to add department to transfers campaign populate
  },
  {
    headerName: "Brand",
    field: "brand_id._id",
    dataSource: "brand",
    cellRenderer: memo(BrandLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.brand_id?.name) {
        return "";
      }
      return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
    },
  },
  {
    headerName: "Created",
    field: "created",
    sort: "desc",
    type: "date",
  },
];

import { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useField } from "formik";

import CustomSelect from "components/Forms/CustomSelect";

import { getBrands } from "../actions";

import CreateBrandModal from "./CreateBrandModal";

/**
 * A component that renders a custom select input for brands. Opens a modal to create a new brand if the user selects the "Create Brand" option.
 * @param {Object} props - The component props.
 * @param {Array} props.brands - The list of brands to display in the select input.
 * @param {Function} props.getBrands - The function to fetch the list of brands.
 * @param {boolean} props.loading - A flag indicating whether the brands are currently being fetched.
 * @param {string} [props.size] - The size of the select input.
 * @returns {JSX.Element} - The rendered component.
 */
const BrandSelect = ({
  brands,
  getBrands,
  loading,
  size = undefined,
  ...props
}) => {
  const [createBrandModalOpen, setCreateBrandModalOpen] = useState(false);
  const [seededBrandName, setSeededBrandName] = useState("");
  const [_field, _meta, helpers] = useField("brand_id");

  useEffect(() => {
    getBrands({ sortKey: "created", sortValue: "-1" });
  }, [getBrands]);

  const brandOptions = brands?.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));

  const toggleCreateBrandModal = useCallback(
    () => setCreateBrandModalOpen(!createBrandModalOpen),
    [createBrandModalOpen, setCreateBrandModalOpen],
  );

  /**
   * Opens modal to create a new brand, and seeds the select filter input as the brand name if provided.
   * @param {string} newBrandName - The default name for the new brand.
   */
  const handleCreateBrand = useCallback(
    (newBrandName) => {
      setSeededBrandName(newBrandName);
      toggleCreateBrandModal();
    },
    [toggleCreateBrandModal],
  );

  const onBrandCreated = useCallback(
    (createdBrand) => {
      helpers.setValue(createdBrand?._id);
    },
    [helpers],
  );
  return (
    <>
      <CustomSelect
        name="brand_id"
        placeholder="Search brands..."
        options={brandOptions}
        isCreatable={true}
        isLoading={loading}
        handleCreateOption={handleCreateBrand}
        size={size}
        {...props}
      />
      <CreateBrandModal
        modalOpen={createBrandModalOpen}
        toggleModal={toggleCreateBrandModal}
        onBrandCreated={onBrandCreated}
        initialBrandName={seededBrandName}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_BRANDS?.pending,
    brands: state.brands.brands,
  };
};

export default connect(mapStateToProps, {
  getBrands,
})(BrandSelect);

import { useMemo } from "react";

import clsx from "clsx";

import { AssignmentsGrid } from "features/assignments";
import { AssignCreator } from "features/creators";

export const CampaignAssignments = ({ campaign_id, className, ...props }) => {
  const classes = clsx("campaign-assignments", className);
  const filterParams = useMemo(() => {
    return {
      campaign_id,
    };
  }, [campaign_id]);
  return (
    <AssignmentsGrid
      filterParams={filterParams}
      className={classes}
      gridId="campaign-assignments"
      excludeDataSources={"campaign"}
      HeaderActionElem={<AssignCreator campaign_id={campaign_id} />}
    />
  );
};

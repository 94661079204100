import BoolIndicator from "components/StatusIndicators/BoolIndicator";

const BoolCellRenderer = ({ value, truthyText, falsyText, ...props }) => {
  return (
    <BoolIndicator
      value={value}
      truthyText={truthyText}
      falsyText={falsyText}
      type="icon"
    />
  );
};

export default BoolCellRenderer;

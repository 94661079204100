import { Outlet } from "react-router-dom";

import { AssignmentsGrid } from "features/assignments/components";

export const AssignmentsListPage = () => {
  return (
    <section className="Assignments-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Assignments List</h1>
        </div>
        <div className="main-page__body">
          <AssignmentsGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
};

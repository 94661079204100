import { Outlet } from "react-router-dom";

import { MilestonesGrid } from "features/milestones/components";

export function MilestonesListPage() {
  return (
    <section className="milestones-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Milestones List</h1>
        </div>
        <div className="main-page__body">
          <MilestonesGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
}

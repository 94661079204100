import { useCallback } from "react";

import { Button } from "reactstrap";

const ViewButtonCellRenderer = ({
  setDisplayed,
  displayed_id,
  actionClick,
  actionText,
  idField,
  ...props
}) => {
  const alreadyDisplayed = displayed_id === props.data?._id;
  const handleClick = useCallback(() => {
    setDisplayed(alreadyDisplayed ? null : props.data);
  }, [alreadyDisplayed, props.data, setDisplayed]);
  const onActionClick = useCallback(() => {
    const rowNode = props.api.getRowNode(props.node.id);
    const data = rowNode.data;
    actionClick(data);
  }, [actionClick, props.api, props.node.id]);

  return (
    <div className="d-flex justify-content-center align-items-center cell-button">
      {setDisplayed && (
        <Button
          outline
          color={alreadyDisplayed ? "secondary" : "dark"}
          className="m-0"
          size="sm"
          onClick={handleClick}
        >
          {alreadyDisplayed ? "Close" : "View"}
        </Button>
      )}
      {actionClick && actionText && (
        <Button
          color="dark"
          outline={actionText === "View"} // TODO: remove temp override
          className="m-0 ms-2"
          size="sm"
          onClick={onActionClick}
        >
          {actionText}
        </Button>
      )}
    </div>
  );
};

export default ViewButtonCellRenderer;

import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { creatorsColDefs } from "features/creators/constants/creatorsColDefs";
import CreateCreatorModalButton from "modules/Creators/components/InviteCreatorModalButton";

/**
 * Renders grid of Creators with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.gridId]
 * @param {string} [props.height]
 * @param {boolean} [props.borders]
 * @param {Object[]} [props.columnDefs]
 * @param {Object} [props.filterParams]
 * @param {number} [props.filterParams.skip=0]
 * @param {number} [props.filterParams.limit=0]
 * @param {string} [props.filterParams.createdFrom]
 * @param {string} [props.filterParams.createdTo]
 * @param {string} [props.filterParams.stripeCustomerId] - Always use `stripeCustomerId` instead of `user_id` whenever possible
 * @param {string} [props.filterParams.user_id]
 * @param {string} [props.filterParams.campaign_id]
 * @param {string} [props.filterParams.brand_id]
 * @param {string} [props.filterParams.assignment_id]
 * @param {string} [props.filterParams.milestone_id]
 * @param {boolean} [props.filterParams.interweaveReversals]
 */
export const CreatorsGrid = ({
  filterParams = {},
  className = "",
  gridId = "",
  ...restProps
}) => {
  const { data, isFetching, refetch }
    = apiSlice.useGetCreatorsQuery(filterParams);
  const classes = clsx("creators-grid", "h-100", className);
  const id = (gridId += ".creators");

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        borders={true}
        columnDefs={creatorsColDefs}
        gridId={id}
        height="100%"
        idField="_id"
        loading={isFetching}
        refetch={refetch}
        quickFilter={true}
        rowData={data?.results ?? []}
        showLinkButton={true}
        linkButtonTo="/creators/:_id"
        HeaderActionElem={<CreateCreatorModalButton />}
        {...restProps}
      />
    </div>
  );
};

import { useMemo } from "react";

import clsx from "clsx";

import { InvoicesGrid } from "features/invoices";

export const CampaignInvoices = ({ campaign_id, className, ...props }) => {
  const classes = clsx("campaign-invoices", className);
  const filterParams = useMemo(() => {
    return {
      campaign_id,
    };
  }, [campaign_id]);
  return (
    <InvoicesGrid
      filterParams={filterParams}
      className={classes}
      gridId="campaign-invoices"
    />
  );
};

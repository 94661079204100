import { memo } from "react";

import CustomSelect from "components/Forms/CustomSelect";
import { EmailField } from "components/Forms/LabelledData";
import WarningBoolIndicator from "components/StatusIndicators/WarningBoolIndicator";
import { countryEnum } from "modules/Creators/constants";

export const creatorDataDefs = (displayedCreator) => {
  return {
    header: {
      type: "string",
      label: "Creator",
      value: displayedCreator?.name,
      editing: true,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            label: "Creator ID",
            fieldKey: "_id",
            type: "id",
            editing: false,
          },
          {
            label: "Name",
            creatorVisible: true,
            fieldKey: "name",
            type: "string",
            hidden: true,
            editing: {
              required: true,
              hidden: false,
            },
          },
          {
            label: "Email",
            creatorVisible: true,
            fieldKey: "email",
            type: "string",
            editing: {
              required: true,
              disableCondition: (values) => {
                return values?._id;
              },
            },
            CustomRenderer: memo(EmailField),
            componentProps: {
              email: displayedCreator?.email,
              isVerified: displayedCreator?.emailVerified,
            },
          },
          {
            label: "Enable Notifications",
            creatorVisible: true,
            fieldKey: "enableNotifications",
            type: "switch",
            editing: {
              required: false,
              defaultValue: true,
            },
          },
          {
            label: "Phone",
            creatorVisible: true,
            fieldKey: "phone",
            type: "string",
            editing: {
              required: false,
            },
          },
          {
            label: "Country",
            creatorVisible: true,
            fieldKey: "country",
            type: "string",
            editing: {
              required: true,
              defaultValue: "US",
              CustomRenderer: (props) => (
                <CustomSelect {...props} name="country" options={countryEnum} />
              ),
            },
          },
          {
            label: "Agency/Creator",
            value: displayedCreator?.isAgency,
            fieldKey: "isAgency",
            type: "string",
            editing: {
              required: true,
              defaultValue: false,
              CustomRenderer: (props) => (
                <CustomSelect
                  {...props}
                  name="isAgency"
                  options={[
                    { value: false, label: "Creator" },
                    { value: true, label: "Agency" },
                  ]}
                />
              ),
            },
            CustomRenderer: memo(WarningBoolIndicator),
            // TODO: custom select input
            componentProps: {
              value: displayedCreator?.isAgency,
              truthyText: "Agency",
              falsyText: "Creator",
              type: "icon",
            },
          },
          {
            label: "Last Login",
            fieldKey: "lastLogin",
            type: "dateTime",
            editing: false,
          },
          {
            label: "First Login",
            fieldKey: "firstLogin",
            type: "dateTime",
            editing: false,
          },
          // {
          //   label: "Social Handles",
          //   value: displayedCreator?.socialHandles,
          //   type: "object",
          //   editing: false,
          //   // TODO: social handles
          // },
          // {
          // // I don't think updated is necessary
          //   label: "Updated",
          //   value: displayedCreator?.updated,
          //   type: "dateTime",
          //   editing: false,
          // },
          {
            label: "Created",
            fieldKey: "created",
            type: "dateTime",
            editing: false,
          },
        ],
      },
      {
        role: "section",
        label: "Balances",
        fields: [
          {
            label: "Withdrawable",
            creatorVisible: true,
            fieldKey: "__availableBalance",
            type: "currency",
            editing: false,
            placement: "details",
          },
          {
            label: "Hold",
            creatorVisible: true,
            fieldKey: "__holdBalance",
            type: "currency",
            editing: false,
            placement: "details",
          },
          {
            label: "Paid",
            creatorVisible: true,
            fieldKey: "__paidBalance",
            type: "currency",
            editing: false,
            placement: "details",
          },
          {
            label: "Earnings",
            creatorVisible: true,
            fieldKey: "__earnings",
            type: "currency",
            editing: false,
            placement: "details",
          },
          {
            label: "Total",
            creatorVisible: true,
            fieldKey: "__totalBalance",
            type: "currency",
            editing: false,
            placement: "details",
          },
        ],
      },
      {
        role: "section",
        label: "Stripe",
        fields: [
          {
            label: "Onboarding",
            creatorVisible: true,
            fieldKey: "needsStripeOnboarding",
            type: "bool",
            editing: false,
            componentProps: {
              value: !displayedCreator?.needsStripeOnboarding,
              truthyText: "Complete",
              falsyText: "Incomplete",
            },
          },
          {
            label: "Financial Account",
            creatorVisible: true,
            fieldKey: "needsStripeFinancialConnection",
            type: "bool",
            editing: false,
            componentProps: {
              value: !displayedCreator?.needsStripeFinancialConnection,
              truthyText: "Linked",
              falsyText: "Not linked",
            },
          },
          {
            label: "Stripe ID",
            fieldKey: "stripeCustomerId",
            type: "id",
            editing: false,
          },
        ],
      },
      // {
      //   role: "section",
      //   label: "Danger Zone",
      //   fields: [
      //     {
      //       label: "Delete Creator",
      //       fieldKey: "__canDeleteUser",
      //       type: "bool",
      //       editing: false,
      //       // TODO only show delete button in edit mode
      //       CustomRenderer: ({ canDelete, canDeleteMessage, props }) => {
      //         return (
      //           <>
      //             {canDelete ? (
      //               <Button
      //                 color="danger"
      //                 outline
      //                 className="d-flex m-1 align-items-center"
      //               >
      //                 {/* //TODO: delete user onclick */}
      //                 <TrashIcon height={20} width={20} className="me-2" />
      //                 Delete
      //               </Button>
      //             ) : (
      //               <span className="text-muted">{`Can't delete - ${canDeleteMessage}`}</span>
      //             )}
      //           </>
      //         );
      //       },
      //       componentProps: {
      //         canDelete: displayedCreator?.__canDeleteUser,
      //         canDeleteMessage: displayedCreator?.__canDeleteUserMessage,
      //       },
      //     },
      //   ],
      // },
    ],
  };
};

import { NavLink as RRNavLink } from "react-router-dom";
import { Button } from "reactstrap";

/**
 * Custom cell renderer for a view link button in a data grid.
 *
 * Receives cellRenderer props from AG Grid
 *
 * @param {Object} props
 * @param {string} props.linkButtonTo - The URL pattern for the link button. Defaults to "./:_id". Replacement keys can be any grid data.
 * @param {boolean} props.linkButtonReplace - Whether to replace the current URL in the history stack with the link button URL when activated. Defaults to false.
 * @param {Object} props.data - The data object for the cell.
 * @returns {JSX.Element} The rendered view link button cell.
 */
const ViewLinkButtonCellRenderer = ({
  linkButtonTo = "./:_id",
  linkButtonReplace = false,
  data,
}) => {
  const url = linkButtonTo.replace(/:([a-z_]+)/gi, (_, key) => {
    const value = data?.[key]?._id || data?.[key]; // check for populated foreignKeys
    return value || `:${key}`;
  });
  return (
    <div className="d-flex justify-content-center align-items-center cell-button">
      <Button
        color="dark"
        outline={true}
        size="sm"
        tag={RRNavLink}
        to={url}
        replace={linkButtonReplace}
      >
        View
      </Button>
    </div>
  );
};

export default ViewLinkButtonCellRenderer;

import { useState } from "react";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import TabbedNav from "components/TabbedNav";
import TabPanes from "components/TabbedNav/TabPanes";
import CampaignTopupModalButton from "modules/Dashboard/components/TopupsCard/CampaignTopupsModalButton";
import PlatformTopupModalButton from "modules/Dashboard/components/TopupsCard/PlatformTopupsModalButton";

import AllTopupsPane from "./AllTopupsPane";
import PastTopupsPane from "./PastTopupsPane";
import PendingTopupsPane from "./PendingTopupsPane";

const topupsTabs = [
  // TODO: milestones and payments
  {
    label: "Pending",
    id: "pending",
    PaneComponent: PendingTopupsPane,
  },
  {
    label: "Past",
    id: "past",
    PaneComponent: PastTopupsPane,
  },
  {
    label: "All",
    id: "all",
    PaneComponent: AllTopupsPane,
  },
];

const TopupsCard = ({ ...props }) => {
  const [stripeActiveTab, setStripeActiveTab] = useState(topupsTabs[0]?.id);

  return (
    <Card className="topups-card">
      <CardBody>
        <CardTitle>
          <h2 className="fs-4">Stripe Topups</h2>
        </CardTitle>
        <div className="d-flex mb-3">
          <PlatformTopupModalButton />
          <CampaignTopupModalButton />
        </div>
        <TabbedNav
          tabs={topupsTabs}
          activeTabId={stripeActiveTab}
          setActiveTabId={setStripeActiveTab}
          title="Topup History"
        />
        <TabPanes
          tabs={topupsTabs}
          activeTabId={stripeActiveTab}
          setActiveTabId={setStripeActiveTab}
        />
      </CardBody>
    </Card>
  );
};

export default TopupsCard;

import { useGetAdminsQuery } from "app/apiSlice";
import CustomSelect from "components/Forms/CustomSelect";

const SelectAdminSigner = ({ ...props }) => {
  const { data, error, isLoading } = useGetAdminsQuery();
  if (!data || error) return null;
  const adminOptions = data?.results?.map((admin) => ({
    value: admin._id,
    label: `${admin.name} (${admin.email})`,
  }));
  return (
    <CustomSelect
      name={props.fieldKey}
      placeholder="Select admin signer..."
      options={adminOptions}
      isCreatable={true}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default SelectAdminSigner;

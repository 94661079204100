import { showToast } from "components/Toasts/helpers/showToast";

import { actionCreator, privateHeaders } from "../../../actions";
import {
  CREATE_BRAND_ERROR,
  CREATE_BRAND_SUCCESS,
  CREATE_ONE_BRAND_URL,
  GET_BRANDS_ERROR,
  READ_MANY_BRANDS_URL,
  brandsActionTypes,
} from "../constants";

/**
 * Action creator function that retrieves a list of brands from the server.
 * @param {Object} query - An optional object containing query parameters to filter the results.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const getBrands = (query = {}) => {
  return async(dispatch) => {
    dispatch(actionCreator(brandsActionTypes.getBrands.REQUEST));
    try {
      let url = new URL(READ_MANY_BRANDS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("calculate", "assignmentBalances");
      // apply query
      Object.keys(query)?.forEach((key) => {
        params.set(key, query[key]);
      });
      url = new URL(url);
      const brandsRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const brands = await fetch(brandsRequest);
      if (!brands || !brands.ok || brands.status !== 200) {
        throw new Error();
      } else {
        const { results } = await brands.json();
        dispatch(actionCreator(brandsActionTypes.getBrands.SUCCESS, results));
      }
    } catch (error) {
      const message = error?.message
        ? `${GET_BRANDS_ERROR}: ${error.message}`
        : GET_BRANDS_ERROR;
      showToast({ message, type: "error" });
      dispatch(actionCreator(brandsActionTypes.getBrands.FAILURE, message));
    }
  };
};

/**
 * Action creator function that creates a new brand on the server.
 * @param {Object} brand - An object containing the details of the brand to be created.
 * @returns {Function} - A redux-thunk function. When dispatched, returns a Promise that either resolves with the created brand data or rejects with an error.
 */
export const createBrand = (brand) => {
  return async(dispatch) => {
    dispatch(actionCreator(brandsActionTypes.createBrand.REQUEST));
    try {
      const createBrandRequest = new Request(CREATE_ONE_BRAND_URL, {
        method: "POST",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(brand),
      });
      // TODO: handle duplicate brand names (backend allows them, frontend should at least warn user)
      const createBrandResponse = await fetch(createBrandRequest);
      if (!createBrandResponse || createBrandResponse.status !== 200) {
        throw new Error();
      } else {
        const { result } = await createBrandResponse.json();
        dispatch(actionCreator(brandsActionTypes.createBrand.SUCCESS, result));
        showToast({ message: CREATE_BRAND_SUCCESS, type: "success" });
        return Promise.resolve(result);
      }
    } catch (error) {
      const message = error?.message
        ? `${CREATE_BRAND_ERROR}: ${error.message}`
        : CREATE_BRAND_ERROR;
      showToast({ message, type: "error" });
      dispatch(actionCreator(brandsActionTypes.createBrand.FAILURE, message));
      return Promise.reject(message);
    }
  };
};

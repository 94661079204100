// Display success/green check for truthy, failure/red x for falsy
// Can be badge with icon, or just icon
import { Badge } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const BooleanIcon = ({ value, applyColor = "", ...props }) => {
  return value ? (
    <CheckIcon
      className={applyColor && "text-success"}
      height={20}
      width={20}
      strokeWidth={2.5}
      {...props}
    />
  ) : (
    <XMarkIcon
      className={applyColor && "text-danger"}
      height={20}
      width={20}
      strokeWidth={2.5}
      {...props}
    />
  );
};

const BoolIndicator = ({
  value,
  type = "badge",
  truthyText = "",
  falsyText = "",
  ...props
}) => {
  const displayText = value ? truthyText : falsyText;
  return (
    <>
      {type === "icon" && (
        <div className={displayText && "d-flex align-items-center"}>
          <BooleanIcon value={value} applyColor="true" />
          {displayText && <span className="ms-2">{displayText}</span>}
        </div>
      )}
      {type === "badge" && (
        <Badge color={value ? "success" : "danger"} pill {...props}>
          <BooleanIcon value={value} />

          <span className="ms-2">{value ? truthyText : falsyText}</span>
        </Badge>
      )}
    </>
  );
};

export default BoolIndicator;

import { useMemo } from "react";

import clsx from "clsx";

import { CreateMilestoneModalButton } from "features/milestones/CreateMilestoneModalButton";
import { MilestonesGrid } from "features/milestones/components";

export const AssignmentMilestones = ({
  assignment_id,
  className,
  ...props
}) => {
  const classes = clsx("assignment-milestones", className);
  const filterParams = useMemo(() => {
    return {
      assignment_id: assignment_id,
    };
  }, [assignment_id]);
  return (
    <MilestonesGrid
      filterParams={filterParams}
      className={classes}
      gridId="assignment-milestones"
      excludeDataSources="assignment"
      // @ts-ignore
      HeaderActionElem={
        <CreateMilestoneModalButton assignment_id={assignment_id} />
      }
    />
  );
};

import { memo } from "react";

import CustomSelect from "components/Forms/CustomSelect";
import { BrandLink } from "features/brands";
import { titleCaseFormatter } from "helpers/formatters";
import BrandSelect from "modules/Brands/components/BrandSelect";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

export const campaignDataDefs = (displayedCampaign) => {
  return {
    header: {
      type: "string",
      label: "Campaign",
      value: displayedCampaign?.name,
      writable: true,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            type: "string",
            label: "Name",
            creatorVisible: true,
            fieldKey: "name",
            hidden: true,
            editing: {
              required: true,
              hidden: false,
            },
          },
          {
            label: "Campaign ID",
            fieldKey: "_id",
            type: "id",
            editing: false,
          },
          {
            label: "Short ID",
            fieldKey: "shortId",
            type: "id",
            editing: {
              disableCondition: (values) => {
                return values?._id;
              },
              hideCondition: (values) => {
                return !values?.enableAccountSegmentation;
              },
            },
          },
          {
            label: "Status",
            value: displayedCampaign?.status,
            fieldKey: "status",
            type: "string",
            CustomRenderer: memo(CampaignStatusBadge),
            editing: {
              required: true,
              CustomRenderer: (props) => (
                <CustomSelect
                  {...props}
                  name="status"
                  // TODO: default to waiting
                  options={[
                    // TODO: use unified campaign status enum dict
                    { value: "active", label: "Active" },
                    { value: "completed", label: "Completed" },
                    { value: "paused", label: "Paused" },
                    { value: "waiting", label: "Waiting" },
                  ]}
                />
              ),
            },
          },
          {
            label: "Brand",
            fieldKey: "brand_id?._id",
            displayValue: displayedCampaign?.brand_id?.name,
            type: "string",
            CustomRenderer: memo(BrandLink),
            componentProps: {
              _id: displayedCampaign?.brand_id?._id,
              data: displayedCampaign,
            },
            editing: {
              required: true,
              fieldKey: "brand_id",
              CustomRenderer: BrandSelect,
              componentProps: {},
            },
          },
          {
            label: "Department",
            fieldKey: "department",
            type: "select",
            displayValue:
              displayedCampaign?.department === "managed"
                ? "Managed Services"
                : titleCaseFormatter(displayedCampaign?.department),
            editing: {
              disableCondition: (values) => {
                return values?._id && values?.department;
              },
              // TODO: @validation case not covered below: if existing campaign is edited, accountSegmentation enabled for the first time, department should be required
              // should be required on all new campaigns. For existing campaigns, required if account segmentation is enabled, otherwise optional
              required:
                !displayedCampaign?._id
                || displayedCampaign?.enableAccountSegmentation,
              CustomRenderer: (props) => (
                <CustomSelect
                  {...props}
                  name="department"
                  options={[
                    { value: "managed", label: "Managed Services" },
                    { value: "talent", label: "Talent" },
                  ]}
                />
              ),
            },
          },
          {
            label: "Enable Account Segmentation",
            fieldKey: "enableAccountSegmentation",
            type: "switch",
            editing: {
              defaultValue: false,
              disableCondition: (values) => {
                return values?._id;
              },
            },
          },

          {
            label: "Client Login Allowed",
            fieldKey: "allowClientAccess",
            type: "switch",
            editing: true,
          },
          {
            label: "Start Date",
            fieldKey: "startDate",
            type: "date",
            editing: true,
          },
          {
            label: "End Date",
            fieldKey: "endDate",
            type: "date",
            editing: true,
          },
          {
            label: "Created Date",
            fieldKey: "created",
            type: "date",
            editing: true,
          },
          {
            label: "Creators",
            value: displayedCampaign?.__assignmentBalances?.count,
            type: "number",
            editing: false,
          },
        ],
      },
      {
        role: "section",
        label: "Balances & Rates",
        fields: [
          // {
          //   label: "Rate per Post",
          //   value: displayedCampaign?.ratePerPost,
          //   type: "currency",
          // },
          {
            label: "Invoiced",
            type: "currency",
            value: displayedCampaign?.invoicedAmount,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
          {
            label: "Funded",
            type: "currency",
            value: displayedCampaign?.fundedAmount,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
          {
            label: "Allocated",
            value: displayedCampaign?.__assignmentBalances?.allocated,
            type: "currency",
          },
          {
            label: "Unallocated",
            value: displayedCampaign?.__assignmentBalances?.unallocated,
            type: "currency",
          },
          {
            label: "Budget",
            type: "currency",
            value: displayedCampaign?.creatorBudget,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
            editing: true,
          },
          {
            label: "Paid to Creators",
            type: "currency",
            value: displayedCampaign?.__assignmentBalances?.paidBalance,
          },
          {
            label: "Hold",
            value: displayedCampaign?.__assignmentBalances?.holdBalance,
            type: "currency",
          },
        ],
      },
      {
        role: "section",
        label: "Accounting",
        fields: [
          {
            label: "SOW Amount",
            type: "currency",
            fieldKey: "sowAmount",
            editing: true,
          },
          // {
          //   label: "Invoiced",
          //   type: "currency",
          //   value: displayedCampaign?.invoicedAmount,
          //   hideCondition: (values) => {
          //     return !values?.enableAccountSegmentation;
          //   },
          // },
          // {
          //   label: "Paid to Creators",
          //   type: "currency",
          //   value: displayedCampaign?.amountPaidToCreators,
          //   hideCondition: (values) => {
          //     return !values?.enableAccountSegmentation;
          //   },
          // },
        ],
      },
      // {
      //   role: "section",
      //   label: "Content Approval",
      //   fields: [
      //     {
      //       label: "Enabled",
      //       value: displayedCampaign?.allowContentApproval,
      //       type: "bool",
      //       writeable: false,
      //       CustomRenderer: BoolIndicator,
      //       cellRendererParams: {
      //         type: "icon",
      //         value: displayedCampaign?.allowContentApproval,
      //       },
      //     },
      //     {
      //       label: "Total Posts",
      //       value: "// TODO: content counts",
      //       type: "number",
      //     },
      //     {
      //       label: "Action Required",
      //       CustomRenderer: () => {
      //         // TODO: link to filtered content
      //         if (!displayedCampaign?.allowContentApproval) {
      //           return <span>No Action Required</span>;
      //         }
      //         return (
      //           <Button color="warning" tag={Link} to={"/content"}>
      //             <ExclamationTriangleIcon
      //               height={20}
      //               width={20}
      //               className="me-2"
      //             />
      //             Review Posts
      //           </Button>
      //         );
      //       },
      //     },

      //     // {
      //     //   label: "Posts Awaiting Admin Action",
      //     //   value:
      //     //     displayedAssignment?.__contentCounts?.awaitingApproval
      //     //     + displayedAssignment?.__contentCounts?.awaitingLinkApproval,
      //     //   type: "number",
      //     // },
      //   ],
      // },
    ],
  };
};

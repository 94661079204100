// import { useGetDeliverablesQuery } from 'app/apiSlice';

import { useState } from "react";

import { Button, Col, ModalBody, Row } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useGetDeliverablesQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { showToast } from "components/Toasts/helpers/showToast";
import DeliverableModal from "features/deliverables/DeliverableModal";
import { deliverableColDefs } from "features/deliverables/constants";

import "./index.scss";

const Deliverables = ({ milestone_id, ...props }) => {
  const [activeDeliverable_id, setActiveDeliverable_id] = useState(null);
  const [deliverableModalOpen, setDeliverableModalOpen] = useState(false);
  const { deliverables, error, isLoading } = useGetDeliverablesQuery(
    { milestone_id },
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        deliverables: data?.results,
        error,
        isLoading,
      }),
      skip: !milestone_id,
    },
  );

  if (error && !isLoading) {
    showToast({
      type: "error",
      message: "Error fetching deliverables.",
    });
  }

  const handleDeliverableClick = ({ _id }) => {
    setActiveDeliverable_id(_id);
    setDeliverableModalOpen(!!_id);
  };

  const handleCreateClick = () => {
    setActiveDeliverable_id(null);
    setDeliverableModalOpen(true);
  };

  return (
    <ModalBody>
      <Row>
        <Col className="d-flex justify-content-end mb-2">
          <Button
            color="dark"
            className="d-flex align-items-center deliverables__create-btn"
            onClick={handleCreateClick}
          >
            <PlusIcon height={20} width={20} className="me-2" />
            {/* // TODO: disable if milestone is complete and add message */}
            New Deliverable
          </Button>
        </Col>
      </Row>
      <DataGrid
        idField="_id"
        gridId="deliverables"
        borders
        columnDefs={deliverableColDefs}
        rowData={deliverables}
        loading={isLoading}
        actionClick={handleDeliverableClick}
        actionText={"View"}
        height="500px"
      />
      <DeliverableModal
        deliverable_id={activeDeliverable_id}
        milestone_id={milestone_id}
        isOpen={deliverableModalOpen}
        setIsOpen={setDeliverableModalOpen}
      />
    </ModalBody>
  );
};

export default Deliverables;

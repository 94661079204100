import { isValidElement } from "react";

import { Nav, NavItem } from "reactstrap";

import { NavLink } from "components/NavLink";

/**
 * Renders a navigation component with tabs. Render tab content separately, this only renders the navigation.
 *
 * @component
 * @param {Object} props
 * @param {Object[]} props.tabs - An array of tab objects.
 * @param {string|React.ReactElement} [props.title] - The title of the navigation component. Optional.
 * @param {boolean} [props.card=true] - Determines whether to apply card styling. Default is true.
 * @returns {JSX.Element} The rendered navigation component.
 */
export const NavTabs = ({
  tabs = [],
  title = undefined, // optional. can be string or react element
  card = true, // apply card styling
  // activeTabId = undefined, // optional. can be string or number
}) => {
  return (
    <div role="tablist">
      <Nav className="d-flex align-items-center gap-1" tabs card={card}>
        {title
          && (isValidElement(title) ? (
            <>{title}</>
          ) : (
            <h2 className="fs-4 m-0 me-3">{title}</h2>
          ))}
        {tabs.map(({ _id, to, label }, index) => (
          <NavItem key={index}>
            <NavLink to={to} replace={true}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {/* {tabs?.map(({ id, TabRowActionComponent }, _index) => {
        if (!activeTabId || id !== activeTabId) return null;
        if (!TabRowActionComponent) return null;
        return (
          <div key={id} className="tab-row-action">
            <TabRowActionComponent />
          </div>
        );
      })} */}
    </div>
  );
};

import { useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Label } from "reactstrap";

import CreatorVisibleIndicator from "./components/CreatorVisibleIndicator";
import getCustomSelectStyles from "./styles/getCustomSelectStyles";

/**
 * An option object for the select component.
 * @typedef {Object} Option
 * @property {string} label - The label for the option.
 * @property {string} value - The value for the option.
 */

/**
 * The props for the custom select component.
 * @typedef {Object} CustomSelectProps
 * @property {string} name - The name for the select component.
 * @property {string} [label] - The label for the select component.
 * @property {string} [placeholder="Select..."] - The placeholder for the select component.
 * @property {boolean} [isCreatable] - Whether the select component is creatable or not.
 * @property {boolean} [isDisabled=undefined] - Whether the select component is disabled or not.
 * @property {Function} [disableCondition] - The function to determine if the select component is disabled.
 * @property {object} [disableConditionCompareValues = {}] - The values to compare for the disable condition.
 * @property {function} [handleCreateOption] - The function to handle creating a new option.
 * @property {string} [size] - The size of the select component.
 * @property {boolean} [isLoading] - Whether the select component is in a loading state or not.
 * @property {Option[]} options - The options for the select component.
 */
const CustomSelect = ({
  name,
  options,
  placeholder = "Select...",
  label = undefined,
  isCreatable = undefined,
  isDisabled = undefined,
  disableCondition = undefined,
  disableConditionCompareValues = {},
  handleCreateOption = undefined,
  size = undefined,
  isLoading = undefined,
  creatorVisible = undefined,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { values } = useFormikContext();

  /**
   * Handles changing the selected option.
   * @param {object} option - The selected option.
   */
  const changeOption = (option) => {
    helpers.setValue(option?.value ?? "");
  };

  /**
   * Gets the option object by its value.
   * @param {string|object} value - The value of the option. May be object if an *_id is populated.
   * @returns {object} - The option object.
   */
  const getOptionByValue = (value) => {
    return (
      options?.find((option) => {
        const compareA = option?.value?._id ?? option?.value;
        const compareB = value?._id ?? value;

        return compareA === compareB;
      }) || ""
    );
  };

  /**
   * Handles creating a new option.
   * @param {string} inputValue - The input value of the new option.
   * @returns {object} - The new option object.
   */
  const onCreateOption = useCallback(
    (inputValue) => handleCreateOption(inputValue),
    [handleCreateOption],
  );

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  const customStyles = getCustomSelectStyles(size);

  const defaultValue = getOptionByValue(field?.value);

  // let disabledConditionResult = false;
  // if (
  //   disableCondition
  //   && disableCondition(values, disableConditionCompareValues)
  // ) {
  //   disabledConditionResult = true;
  // }

  const disableConditionResult = useMemo(() => {
    if (disableCondition) {
      return disableCondition(values, disableConditionCompareValues);
    }
  }, [disableCondition, disableConditionCompareValues, values]);

  return (
    <>
      {label && (
        <Label className="small text-muted" htmlFor={name}>
          <CreatorVisibleIndicator
            isVisible={!!creatorVisible}
            showIcon={true}
            iconPosition="static"
          >
            {label}
          </CreatorVisibleIndicator>
        </Label>
      )}
      <SelectComponent
        {...field}
        id={name}
        name={name}
        options={options}
        value={getOptionByValue(field?.value)}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={changeOption}
        onBlur={field.onBlur}
        isSearchable={true}
        onCreateOption={onCreateOption}
        isValidNewOption={isValidNewOption}
        aria-labelledby={label ? name : null}
        aria-invalid={!!meta.error}
        aria-describedby={`${name}-error`}
        isLoading={isLoading}
        isDisabled={isDisabled ?? disableConditionResult}
        classNames={{
          control: () => {
            return size === "sm" ? "form-control-sm" : "form-control";
          },
        }}
        className={`custom-select ${meta.touched && meta.error ? "error" : ""}`}
        styles={{
          control: customStyles.control,
          indicatorsContainer: customStyles.indicatorsContainer,
        }}
      />
      {meta.touched && meta.error ? (
        <div id={`${name}-error`} className="error" aria-live="polite">
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

/**
 * Checks if a new option is valid for the select component.
 * @param {string} inputValue - The input value of the new option.
 * @param {object} selectValue - The current value of the select component.
 * @param {array} selectOptions - The options of the select component.
 * @returns {boolean} - Whether the new option is valid or not.
 */
function isValidNewOption(inputValue, selectValue, selectOptions) {
  return (
    inputValue.trim().length > 0
    && !selectOptions.find((option) => option.label === inputValue)
  );
}

export default CustomSelect;

import { useCallback, useState } from "react";

import { useField } from "formik";

import { apiSlice } from "app/apiSlice";
import CustomSelect from "components/Forms/CustomSelect";

export const CampaignSelect = ({ size = undefined, ...props }) => {
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);
  const [seededCampaignName, setSeededCampaignName] = useState("");
  const [_field, _meta, helpers] = useField("campaign_id");

  // useEffect(() => {
  //   getCampaigns({ sortKey: "created", sortValue: "-1" });
  // }, [getCampaigns]);
  const { data, isLoading } = apiSlice.useGetCampaignsQuery({
    sortKey: "updated",
    sortValue: "-1",
  });
  const campaigns = data?.results;

  const campaignOptions = campaigns?.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));

  const toggleCreateCampaignModal = useCallback(
    () => setCreateCampaignModalOpen(!createCampaignModalOpen),
    [createCampaignModalOpen, setCreateCampaignModalOpen],
  );

  /**
   * Opens modal to create a new campaign, and seeds the select filter input as the campaign name if provided.
   * @param {string} newCampaignName - The default name for the new campaign.
   */
  const handleCreateCampaign = useCallback(
    (newCampaignName) => {
      setSeededCampaignName(newCampaignName);
      toggleCreateCampaignModal();
    },
    [toggleCreateCampaignModal],
  );

  const onCampaignCreated = useCallback(
    (createdCampaign) => {
      helpers.setValue(createdCampaign?._id);
    },
    [helpers],
  );
  return (
    <>
      <CustomSelect
        name="campaign_id"
        placeholder="Search campaigns..."
        options={campaignOptions}
        isCreatable={false}
        isLoading={isLoading}
        handleCreateOption={handleCreateCampaign}
        size={size}
        {...props}
      />
    </>
  );
};

import { isValidElement } from "react";

/**
 * Renders a set of tab panes.
 *
 * @param {Object} props - The component props.
 * @param {(String|Number)} props.activeTabId - The ID of the active tab.
 * @param {Function} props.setActiveTabId - Function to set the active tab ID.
 * @param {Object[]} props.tabs - Array of tab definitions.
 * @param {String} props.tabs.label - Label for the tab.
 * @param {(String|Number)} props.tabs.id - ID for the tab.
 * @param {React.ElementType} props.tabs.PaneComponent - React component for the tab pane.
 * @param {Object} [props.paneComponentProps] - An optional object of props to pass to the pane component.
 *
 * @returns {JSX.Element} - The rendered tab panes.
 */
const TabPanes = ({
  activeTabId,
  setActiveTabId,
  tabs = [],
  paneComponentProps = {},
  ...props
}) => {
  const activeId = activeTabId ?? 0;
  return (
    <>
      {tabs?.map(({ id, PaneComponent }, _index) => {
        if (!PaneComponent) return null;
        if (!activeTabId || id !== activeTabId) return null;
        if (isValidElement(<PaneComponent />)) {
          return (
            <div key={id} className="tab-pane">
              <PaneComponent
                activeTabId={activeId}
                tabId={id}
                setActiveTabId={setActiveTabId}
                {...paneComponentProps}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default TabPanes;

import { useCallback, useState } from "react";

import { connect } from "react-redux";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { currencyFormatter } from "helpers/formatters";
import { getChangedValues } from "helpers/getChangedValues";
import useAuthorizationCheck from "hooks/useAuthorizationCheck";
import { makePayment } from "modules/Campaigns/actions";

import { mileStonePaymentFields } from "./constants";

const PayMilestoneModal = ({
  isOpen,
  onClosed,
  toggle,
  milestone,
  assignment,
  makePayment,
  loading,
  user,
  ...props
}) => {
  const [submissionError, setSubmissionError] = useState(null);
  // const { dirty, resetForm } = useFormikContext();

  const canPay = useAuthorizationCheck(["make_payment"]);

  const toggleModal = useCallback(() => {
    // if (isOpen && dirty) {
    //   const confirm = window.confirm(
    //     "You have unsaved changes. Are you sure you want to leave?",
    //   );
    //   if (confirm) {
    //     toggle();
    //   }
    //   return;
    // }
    toggle();
  }, [toggle]);

  const closedModal = useCallback(() => {
    // resetForm();
    setSubmissionError(null);
    onClosed();
  }, [onClosed]);

  const initialValues = getInitialValues(mileStonePaymentFields, milestone);

  const validate = useCallback(
    (values) => {
      const errors = {};
      mileStonePaymentFields?.forEach((field) => {
        // @ts-ignore
        if (field?.editing?.required && !values[field?.fieldKey]) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (values?.offPlatformPayment) {
          if (
            (field?.fieldKey === "offPlatformAmount"
              && !values?.offPlatformAmount)
            || (field?.fieldKey === "offPlatformReferenceId"
              && !values?.offPlatformReferenceId)
            || (field?.fieldKey === "offPlatformPlatform"
              && !values?.offPlatformPlatform)
            || (field?.fieldKey === "offPlatformCreated"
              && !values?.offPlatformCreated)
          ) {
            errors[field?.fieldKey] = `This field is required`;
          }
        }

        if (
          field?.fieldKey === "paymentType"
          && !values?.paymentType
          && !values?.offPlatformPayment
        ) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (values?.paymentType === "partial" && !values?.offPlatformPayment) {
          if (field?.fieldKey === "amountPartial") {
            if (!values.amountPartial) {
              errors[field.fieldKey] = `Please enter a payment amount`;
            }
            if (values.amountPartial > milestone?.holdBalance) {
              errors[field?.fieldKey]
                = `Payment amount cannot be greater than the amount owed`;
            }
          }
        }
      });

      return errors;
    },
    [milestone],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClosed={closedModal}
      onExit={closedModal}
      toggle={toggleModal}
      unmountOnClose={true}
      className="milestone-modal"
      backdrop="static"
    >
      {loading && <Loader />}
      <Formik
        initialValues={initialValues}
        onSubmit={async(values, actions) => {
          setSubmissionError(null);
          if (values?.paymentType === "partial") {
            values.amount = values.amountPartial;
          }
          if (values?.paymentType === "full") {
            values.amount = milestone?.holdBalance;
          }
          if (values?.offPlatformPayment) {
            delete values.status;
            delete values.amount;
            values.offPlatformCreatedByAdminEmail = user?.email;
          }
          const changedValues = getChangedValues(values, initialValues);
          if (
            window.confirm(
              `Confirm submitting ${
                values?.offPlatformPayment
                  ? `off-platform (${values?.offPlatformPlatform})`
                  : values?.paymentType
              } payment: ${currencyFormatter(
                values?.amount ?? values?.offPlatformAmount,
              )}`,
            )
          ) {
            try {
              if (!Object.keys(changedValues).length) {
                throw new Error("No changes detected.");
              }
              await makePayment({
                milestone: {
                  ...changedValues,
                  _id: milestone?._id,
                  assignment_id: milestone?.assignment_id,
                },
                assignment_id: assignment?._id,
              });
              toggleModal();
            } catch (error) {
              setSubmissionError("There was an error submitting payment.");
            }
          }
          actions.setSubmitting(false);
        }}
        validate={validate}
        enableReinitialize={true}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            {/* // TODO: confirm payment */}
            <UnsavedChangesPrompt />
            <ModalHeader toggle={toggleModal}>Pay Milestone</ModalHeader>
            {canPay ? (
              <>
                <ModalBody>
                  {mileStonePaymentFields?.map((def, i) => {
                    return (
                      <LabelledData
                        key={i}
                        definition={def}
                        isEditing={true}
                        data={milestone || {}}
                        hideConditionCompareValues={assignment}
                      />
                    );
                  })}
                  <Alert color="danger" isOpen={!!submissionError}>
                    {submissionError}
                  </Alert>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModal}>
                    Cancel
                  </Button>
                  <Button
                    color="dark"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    Pay
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <ModalBody>
                <Alert color="danger">
                  Your account is not permitted to make payments. Please contact
                  development if this is in error.
                </Alert>
              </ModalBody>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.MAKE_PAYMENT?.pending,
    user: state.login.user,
  };
};
export default connect(mapStateToProps, {
  makePayment,
})(PayMilestoneModal);

import { useCallback } from "react";

import { Button } from "reactstrap";

const Tab = ({ children, tabId, activeTabId, setActiveTabId, ...props }) => {
  const setTabId = useCallback(() => {
    if (!setActiveTabId || typeof setActiveTabId !== "function") {
      return;
    }
    setActiveTabId(tabId);
  }, [setActiveTabId, tabId]);

  return (
    <Button
      className={`rounded-pill m-1 ${activeTabId === tabId ? "isActive" : ""}`}
      color={activeTabId === tabId ? "primary" : "secondary"}
      outline
      onClick={setTabId}
    >
      {children}
    </Button>
  );
};
export default Tab;

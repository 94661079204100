import { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";

import { assignmentsDueColDefs } from "modules/Dashboard/constants/colDefs";

import DataGrid from "../../../../components/DataGrid";
import { getAssignmentsDue } from "../../actions";

import AssignmentMilestonesModal from "./AssignmentMilestonesModal";

const AssignmentsDue = ({
  assignmentsDue,
  loading,
  getAssignmentsDue,
  activeTabId,
  tabId,
  ...props
}) => {
  useEffect(() => {
    getAssignmentsDue();
  }, [getAssignmentsDue]);
  const [assignmentMilestonesModalOpen, setAssignmentMilestonesModalOpen]
    = useState(false);
  const [activeAssignment, setActiveAssignment] = useState(null);

  const toggleAssignmentMilestonesModal = useCallback(
    (assignment) => {
      setActiveAssignment(assignment);
      setAssignmentMilestonesModalOpen((isOpen) => !isOpen);
    },
    [setAssignmentMilestonesModalOpen],
  );
  const refreshAssignments = useCallback(async() => {
    let assignments;
    try {
      assignments = await getAssignmentsDue();
    } catch (error) {
      console.error(error);
    }
    if (activeAssignment) {
      setActiveAssignment(
        assignments.find(
          (assignment) => assignment._id === activeAssignment?._id,
        ),
      );
    }
  }, [getAssignmentsDue, setActiveAssignment, activeAssignment]);
  return (
    <div className="assignments-due">
      <DataGrid
        gridId="assignments-due"
        borders
        loading={loading}
        visible={activeTabId === tabId}
        autoSizeEnabled={true}
        autoSizeIgnoreHeaders={true}
        idField="_id"
        rowData={assignmentsDue}
        columnDefs={assignmentsDueColDefs}
        actionClick={toggleAssignmentMilestonesModal}
        actionText={"View"}
        quickFilter={true}
        appliedFilters={true}
      />
      <AssignmentMilestonesModal
        isOpen={assignmentMilestonesModalOpen}
        onClosed={refreshAssignments}
        toggle={toggleAssignmentMilestonesModal}
        assignment={activeAssignment}
        type="due"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    assignmentsDue: state.dashboard.assignmentsDue,
    loading: state.loading.GET_ASSIGNMENTS_DUE?.pending,
  };
};
export default connect(mapStateToProps, {
  getAssignmentsDue,
})(AssignmentsDue);

import { useCallback, useMemo, useState } from "react";

import { connect } from "react-redux";
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap";

import DataGrid from "components/DataGrid";
import MilestoneModal from "components/Milestones/MilestoneModal";
import PayMilestoneModal from "components/Milestones/PayMilestoneModal";
import { milestoneColDefs } from "components/Milestones/constants";
import { showToast } from "components/Toasts/helpers/showToast";

const AssignmentMilestonesModal = ({
  isOpen,
  onClosed,
  toggle,
  assignment,
  type, // due or upcoming
  ...props
}) => {
  const [payMilestoneModalOpen, setPayMilestoneModalOpen] = useState(false);
  const [viewMilestoneModalOpen, setViewMilestoneModalOpen] = useState(false);
  const [activeMilestone, setActiveMilestone] = useState(false);

  const toggleModal = useCallback(() => {
    toggle();
  }, [toggle]);

  const closedModal = useCallback(() => {
    onClosed();
  }, [onClosed]);

  const togglePayMilestoneModal = useCallback(() => {
    setPayMilestoneModalOpen(!payMilestoneModalOpen);
  }, [setPayMilestoneModalOpen, payMilestoneModalOpen]);

  const toggleViewMilestoneModal = useCallback(() => {
    setViewMilestoneModalOpen(!viewMilestoneModalOpen);
  }, [setViewMilestoneModalOpen, viewMilestoneModalOpen]);

  const payMilestone = useCallback(
    (item) => {
      if (!item) {
        return;
      }
      if (!item.isComplete) {
        showToast({
          message: "Milestone must be completed before it can be paid.",
        });
        return;
      }
      if (!item.releasePayment) {
        showToast({
          message: "Milestone must be released before it can be paid.",
        });
        return;
      }
      setActiveMilestone(item);
      setPayMilestoneModalOpen(true);
    },
    [setActiveMilestone, setPayMilestoneModalOpen],
  );

  const viewMilestone = useCallback(
    (item) => {
      if (!item) {
        return;
      }
      setActiveMilestone(item);
      setPayMilestoneModalOpen(true);
    },
    [setActiveMilestone, setPayMilestoneModalOpen],
  );

  const actionClick = useCallback(
    (item) => {
      if (type === "due") {
        payMilestone(item);
      } else {
        viewMilestone(item);
      }
    },
    [type, payMilestone, viewMilestone],
  );

  const actionText = useMemo(() => {
    if (type === "due") {
      return "Pay";
    } else {
      return "View";
    }
  }, [type]);

  const gridId = useMemo(() => {
    let id = "dashboard-assignment-milestones";
    if (type === "due") {
      id += "-due";
    } else {
      id += "-upcoming";
    }
    return id;
  }, [type]);

  return (
    <Modal
      isOpen={isOpen}
      onClosed={closedModal}
      onExit={closedModal}
      toggle={toggleModal}
      unmountOnClose={true}
      className="assignment-milestones-modal"
      size="xl"
      backdrop="static"
    >
      <ModalHeader toggle={toggleModal}>
        <div className="fs-4">
          <Label className="text-muted small mb-0 me-2">
            Assignment Milestones:
          </Label>
          {assignment?.campaignName} | {assignment?.name}
        </div>
      </ModalHeader>
      <ModalBody>
        <DataGrid
          gridId={gridId}
          borders
          autoSizeEnabled={true}
          autoSizeIgnoreHeaders={true}
          idField="_id"
          height={"500px"}
          rowData={assignment?.milestones}
          columnDefs={milestoneColDefs}
          displayedItem={activeMilestone}
          actionClick={actionClick}
          actionText={actionText}
        />
        <PayMilestoneModal
          isOpen={payMilestoneModalOpen}
          toggle={togglePayMilestoneModal}
          onClosed={closedModal}
          milestone={activeMilestone}
          assignment={assignment}
          {...props}
        />
        <MilestoneModal
          isOpen={viewMilestoneModalOpen}
          toggle={toggleViewMilestoneModal}
          onClosed={closedModal}
          milestone={activeMilestone}
          assignment={assignment}
          {...props}
        />
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (_state) => {
  return {};
};
export default connect(mapStateToProps, {})(AssignmentMilestonesModal);

import { memo } from "react";

import { CreatorLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/CreatorLinkCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";

export const creatorsColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "_id",
    cellRenderer: memo(CreatorLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the creator's name and ID searchable
      if (!params.data?.name) {
        return "";
      }
      return `${params.data?.name} ${params.data?._id}`;
    },
  },
  {
    headerName: "Agency",
    field: "isAgency",
    valueGetter: (params) => {
      return params.data.isAgency ? "Agency" : "Creator";
    },
    // TODO: customize filter to behave like boolean - should be only two options in dropdown for 'creator' or 'agency'
  },
  {
    headerName: "Email",
    field: "email",
    type: "text",
    width: 200,
  },
  {
    headerName: "Verified Email",
    field: "emailVerified",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Country",
    field: "country",
    type: "text",
  },
  {
    headerName: "Stripe Onboarding",
    field: "needsStripeOnboarding",
    type: "boolean",
    cellDataType: "boolean",
    valueGetter: (params) => {
      return !params.data.needsStripeOnboarding;
    },
    cellRenderer: memo(BoolCellRenderer),
    cellRendererParams: {
      truthyText: "Complete",
      falsyText: "Incomplete",
    },
  },
  {
    headerName: "Financial Account",
    field: "needsStripeFinancialConnection",
    type: "boolean",
    valueGetter: (params) => {
      return !params.data.needsStripeFinancialConnection;
    },
    cellRenderer: memo(BoolCellRenderer),
    cellRendererParams: {
      truthyText: "Linked",
      falsyText: "Not linked",
    },
  },
  {
    headerName: "Hold",
    field: "__holdBalance",
    type: "currency",
  },
  {
    // TODO: handle __availableBalanceCurrency?
    headerName: "Withdrawable",
    field: "__availableBalance",
    type: "currency",
  },
  {
    headerName: "Cashed Out",
    field: "__paidBalance",
    type: "currency",
  },
  {
    headerName: "Earned",
    field: "__earnings",
    type: "currency",
  },
  {
    headerName: "Last Login",
    sort: "desc", // initial sort order on load
    field: "lastLogin",
    type: "date",
  },
  {
    headerName: "Created",
    field: "created",
    type: "date",
  },
];

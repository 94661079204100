import clsx from "clsx";

import { TabPane } from "./TabPane";

/**
 * Renders a set of tab panes with content defined in the tabs config.
 * Extra props are passed to each tab pane.
 */
export const TabContent = ({
  tabs = [],
  activeTabId,
  className = "",
  ...props
}) => {
  const classes = clsx("tab-content", "h-100", className);
  return (
    <div className={classes}>
      {tabs?.map(({ id, Pane }, _index) => {
        if (!Pane) return null;
        return (
          <TabPane key={id} active={activeTabId === id}>
            <Pane {...props} />
          </TabPane>
        );
      })}
    </div>
  );
};

// ? what fields? what is important for admins? emulate boldsign UI?
export const agreementsColDefs = [
  {
    headerName: "Title",
    field: "title",
    type: "text",
  },
  {
    // TODO: status chips
    // ? is revoked a separate field or will status be updated?
    // ? what about "admin sent" and "admin signed"
    headerName: "Status",
    field: "status",
    type: "text",
  },
  {
    headerName: "Action Required (Admin)",
    field: "__isAdminActionRequired",
    type: "boolean",
    sort: "desc",
  },
  {
    headerName: "Creator",
    field: "user_id.name",
    type: "text",
  },
  {
    // TODO: populate assignment in grid
    headerName: "Campaign Name",
    field: "assignment_id.campaignName",
    type: "text",
  },
  {
    headerName: "Creator Signed?",
    field: "creatorHasSigned",
    // TODO: boolean indicators
    type: "boolean",
  },
  {
    headerName: "Required for Payment?",
    field: "isRequired",
    // TODO: boolean indicators
    type: "boolean",
  },
  {
    headerName: "Sent to Creator?",
    field: "adminHasSent",
    // TODO: boolean indicators
    type: "boolean",
  },
  {
    headerName: "Admin Signature Required",
    field: "adminEmailAddress",
    type: "text",
  },
  {
    headerName: "Admin Signed?",
    field: "adminHasSigned",
    // TODO: boolean indicators
    type: "boolean",
  },
  {
    headerName: "Created",
    field: "created",
    type: "date",
  },
  {
    headerName: "Updated",
    field: "updated",
    type: "date",
  },
  // TODO: clickable to view agreement
];

import { useMemo } from "react";

import clsx from "clsx";

import { InvoicesGrid } from "features/invoices";

export const BrandInvoices = ({ brand_id, className, ...props }) => {
  const classes = clsx("brand-invoices", className);
  const filterParams = useMemo(() => {
    return {
      brand_id,
    };
  }, [brand_id]);
  return (
    <InvoicesGrid
      filterParams={filterParams}
      className={classes}
      gridId="brand-invoices"
    />
  );
};

import { Button } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/outline";

/**
 * Renders a button component for removing an assignment.
 *
 * @deprecated - Use new RemoveAssignmentButton component in /features instead.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.assignment - The assignment object to be removed.
 * @param {boolean} props.canDelete - Indicates whether the assignment can be deleted.
 * @param {array} [props.cantDeleteReasons] - Array of messages explaining why the assignment can't be deleted. If none are provided and canDelete is false, a generic message will be displayed.
 * @param {Function} props.onRemove - The function to be called when the assignment is removed. Accepts the assignment object as a parameter.
 * @returns {JSX.Element} The RemoveAssignmentButton component.
 */
const RemoveAssignmentButton = ({
  assignment,
  canDelete,
  cantDeleteReasons = [],

  onRemove,
}) => {
  return (
    <div className="remove-assignment-button">
      {canDelete ? (
        <Button
          color="danger"
          outline
          className="d-flex m-1 align-items-center remove-assignment-button__button"
          disabled={!canDelete}
          onClick={() => {
            if (!canDelete) return;
            onRemove(assignment);
          }}
        >
          <XMarkIcon height={20} width={20} className="me-2" />
          Remove
        </Button>
      ) : (
        <div className="remove-assignment-button__errors">
          <span>
            {/* Can't remove from campaign: */}
            Can&apos;t remove from campaign:
          </span>
          {cantDeleteReasons.length > 0 ? (
            <>
              {cantDeleteReasons.map((reason, index) => (
                <span key={index}>{reason}</span>
              ))}
            </>
          ) : (
            <>
              <span>
                Assignments with a paid balance, available balance, or completed
                milestones cannot be removed.
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RemoveAssignmentButton;

import { useMemo } from "react";

import { apiSlice } from "app/apiSlice";
import { getSelectGridColDefs } from "components/DataGrid/helpers/getSelectGridColDefs";
import { CreatorsGrid } from "features/creators/components";
import { creatorsColDefs } from "features/creators/constants/creatorsColDefs";

const selectCreatorColDefs = getSelectGridColDefs(creatorsColDefs);

/**
 * A component that renders a list of campaigns with checkboxes and allows the user to select one.
 *
 * @param {Object} props - The component props.
 * @param {function} props.setSelectedId - A function to set the selected campaign ID when the user makes a selection change.
 * @param {string} props.campaign_id - ID of the campaign the creator will be assigned to.
 * @returns {JSX.Element} - The SelectCampaign component.
 */
export const SelectCreatorToAssign = ({
  setSelectedId,
  campaign_id,
  ...props
}) => {
  const { assignedCreatorIds } = apiSlice.useGetAssignmentsQuery(
    { campaign_id },
    {
      selectFromResult: ({ data }) => ({
        assignedCreatorIds: data?.results?.map(
          (assignment) => assignment?.user_id?._id ?? assignment?.user_id,
        ),
      }),
    },
  );

  // TODO: make this a custom hook
  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedNodes();
    if (selectedRows.length > 0) {
      setSelectedId(selectedRows[0].id);
    } else {
      setSelectedId(null);
    }
  };

  const isRowSelectable = useMemo(() => {
    return (rowNode) => {
      return !assignedCreatorIds.includes(rowNode.id);
    };
  }, [assignedCreatorIds]);

  return (
    <>
      <div className="mb-3">
        <small className="text-muted">
          Note: Creators without checkboxes mean the creator is already assigned
          to this campaign.
        </small>
      </div>
      <CreatorsGrid
        gridId="creators-select"
        columnDefs={selectCreatorColDefs}
        height="500px"
        borders
        // @ts-ignore
        onSelectionChanged={handleSelectionChanged}
        isRowSelectable={isRowSelectable}
        showLinkButton={false}
        HeaderActionElem={null}
      />
    </>
  );
};

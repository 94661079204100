import { useCallback } from "react";

import { Button } from "reactstrap";

import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";

/**
 * Renders a button to trigger agreement signing.
 *
 * Must be a descendant of an AgreementIdContext.Provider.
 *
 * @returns {React.ReactNode} - The rendered component or null if no adminEmbeddedSigningUrl exists on the agreement.
 */
const AgreementSignButton = () => {
  const { agreement } = useAgreementData();
  const { setAgreementUIStatus } = useAgreementContext();

  const onClick = useCallback(() => {
    setAgreementUIStatus("sign");
  }, [setAgreementUIStatus]);

  if (!agreement?.adminEmbeddedSigningUrl) {
    return null;
  }

  return (
    <>
      <Button onClick={onClick} color="primary">
        Begin Signing
      </Button>
    </>
  );
};

export default AgreementSignButton;

import { useMemo } from "react";

import clsx from "clsx";

import { MilestonesGrid } from "features/milestones/components";

export const CampaignMilestones = ({ campaign_id, className, ...props }) => {
  const classes = clsx("campaign-milestones", className);
  const filterParams = useMemo(() => {
    return {
      campaign_id: campaign_id,
    };
  }, [campaign_id]);
  return (
    <MilestonesGrid
      filterParams={filterParams}
      className={classes}
      gridId="campaign-milestones"
      excludeDataSources="campaign"
    />
  );
};

import clsx from "clsx";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { invoicesColDefs } from "features/invoices/constants/invoicesColDefs";

export const InvoicesGrid = ({
  filterParams = {},
  className = "",
  gridId = "",
}) => {
  const { data, isFetching, refetch }
    = apiSlice.useGetInvoicesQuery(filterParams);
  const classes = clsx("invoices-grid", "h-100", className);
  const id = (gridId += ".invoices");

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        borders={true}
        columnDefs={invoicesColDefs}
        gridId={id}
        height="100%"
        idField="_id"
        loading={isFetching}
        refetch={refetch}
        quickFilter={true}
        rowData={data?.results ?? []}
      />
    </div>
  );
};

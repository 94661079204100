import { useMemo, useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { Button, Label } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { Assignment } from "features/assignments/components/Assignment";
import { assignmentDataDefs } from "features/assignments/constants/assignmentDataDefs";

export const AssignmentPage = () => {
  const { assignment_id } = useParams();
  const { data } = apiSlice.useGetAssignmentQuery({ _id: assignment_id });
  const assignmentDefs = useMemo(() => assignmentDataDefs(data), [data]);

  const [isEditing, setIsEditing] = useState(false);

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div className="assignment-page">
      <Panel key={assignment_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/assignments"} className="my-1">
          <h1 className="fs-2 d-flex align-items-center">
            <Label className="text-muted small">Assignment:</Label>
            <LabelledData
              definition={assignmentDefs?.header}
              isEditing={false}
              data={data}
            />
            {!isEditing && (
              <Button
                color="secondary"
                outline
                size="sm"
                className="d-flex align-items-center ms-3"
                onClick={() => setIsEditing(true)}
              >
                <PencilIcon height={20} width={20} />
              </Button>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split="vertical">
          <Assignment
            assignment_id={assignment_id}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <Outlet />
        </PanelBody>
      </Panel>
    </div>
  );
};

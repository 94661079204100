import DataGrid from "components/DataGrid";

import { topupsColDefs } from "./constants/columnDefs";
import { apiSlice } from "app/apiSlice";

const PendingTopupsPane = ({
  activeTabId,
  tabId,
  ...props
}) => {
  const { data, isLoading } = apiSlice.useGetTopupsQuery();
  const pendingTopups = data?.result?.allPendingTopups ?? [];
  return (
    <DataGrid
      gridId="pending-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={pendingTopups}
      columnDefs={topupsColDefs}
      loading={isLoading}
    />
  );
};

export default PendingTopupsPane;

import { useMemo } from "react";

import clsx from "clsx";

import { AssignmentsGrid } from "features/assignments";

export const BrandAssignments = ({ brand_id, className, ...props }) => {
  const classes = clsx("brand-assignments", className);
  const filterParams = useMemo(() => {
    return {
      brand_id,
    };
  }, [brand_id]);
  return (
    <AssignmentsGrid
      filterParams={filterParams}
      className={classes}
      gridId="brand-assignments"
      excludeDataSources="brand"
    />
  );
};

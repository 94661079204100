import { memo } from "react";

import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

import { EmailField } from "components/Forms/LabelledData";
import WarningBoolIndicator from "components/StatusIndicators/WarningBoolIndicator";
import { titleCaseFormatter } from "helpers/formatters";

import CustomSelect from "../../../components/Forms/CustomSelect";
import BrandSelect from "../../Brands/components/BrandSelect";
import RemoveCreatorAssignmentButton from "../components/Campaign/RemoveCreatorAssignmentButton";
import CampaignStatusBadge from "../components/CampaignStatusBadge";

export const campaignDetailsDataDefs = (displayedCampaign) => {
  return {
    header: {
      type: "string",
      label: "Campaign",
      value: displayedCampaign?.name,
      writable: true,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            type: "string",
            label: "Name",
            creatorVisible: true,
            fieldKey: "name",
            hidden: true,
            editing: {
              required: true,
              hidden: false,
            },
          },
          {
            label: "Campaign ID",
            fieldKey: "_id",
            type: "id",
            editing: false,
          },
          {
            label: "Short ID",
            fieldKey: "shortId",
            type: "id",
            editing: {
              disableCondition: (values) => {
                return values?._id;
              },
              hideCondition: (values) => {
                return !values?.enableAccountSegmentation;
              },
            },
          },
          {
            label: "Status",
            value: displayedCampaign?.status,
            fieldKey: "status",
            type: "string",
            CustomRenderer: CampaignStatusBadge,
            editing: {
              required: true,
              CustomRenderer: (props) => (
                <CustomSelect
                  {...props}
                  name="status"
                  // TODO: default to waiting
                  options={[
                    // TODO: use unified campaign status enum dict
                    { value: "active", label: "Active" },
                    { value: "completed", label: "Completed" },
                    { value: "paused", label: "Paused" },
                    { value: "waiting", label: "Waiting" },
                  ]}
                />
              ),
            },
          },
          {
            label: "Brand",
            fieldKey: "brand_id?._id",
            displayValue: displayedCampaign?.brand_id?.name,
            type: "string",
            editing: {
              required: true,
              fieldKey: "brand_id",
              CustomRenderer: BrandSelect,
            },
          },
          {
            label: "Department",
            fieldKey: "department",
            type: "string",
            displayValue:
              displayedCampaign?.department === "managed"
                ? "Managed Services"
                : titleCaseFormatter(displayedCampaign?.department),
            editing: {
              disableCondition: (values) => {
                return values?._id && values?.department;
              },
              required: true, // TODO: @segmentation validation
              CustomRenderer: (props) => (
                <CustomSelect
                  {...props}
                  name="department"
                  options={[
                    { value: "managed", label: "Managed Services" },
                    { value: "talent", label: "Talent" },
                  ]}
                />
              ),
            },
          },
          {
            label: "Enable Account Segmentation",
            fieldKey: "enableAccountSegmentation",
            type: "switch",
            editing: {
              defaultValue: false,
              disableCondition: (values) => {
                return values?._id;
              },
            },
          },
          
          {
            label: "Allow Client Login",
            fieldKey: "allowClientAccess",
            type: "switch",
            editing: true,
          },
          {
            label: "Start Date",
            fieldKey: "startDate",
            type: "date",
            editing: true,
          },
          {
            label: "End Date",
            fieldKey: "endDate",
            type: "date",
            editing: true,
          },
          {
            label: "Creators",
            value: displayedCampaign?.__assignmentBalances?.count,
            type: "number",
            editing: false,
          },
        ],
      },
      {
        role: "section",
        label: "Balances & Rates",
        fields: [
          {
            label: "Total Rate",
            type: "currency",
            value:
              displayedCampaign?.__assignmentBalances?.paidBalance
              + displayedCampaign?.__assignmentBalances?.availableBalance
              + displayedCampaign?.__assignmentBalances?.holdBalance,
          },
          {
            label: "Rate per Post",
            value: displayedCampaign?.ratePerPost,
            type: "currency",
            writeable: true,
          },
          {
            label: "Paid",
            type: "currency",
            value: displayedCampaign?.__assignmentBalances?.paidBalance,
          },
          {
            label: "Hold",
            value: displayedCampaign?.__assignmentBalances?.holdBalance,
            type: "currency",
          },
          {
            label: "Allocated",
            value: displayedCampaign?.__assignmentBalances?.allocated,
            type: "currency",
          },
          {
            label: "Unallocated",
            value: displayedCampaign?.__assignmentBalances?.unallocated,
            type: "currency",
          },
          {
            label: "Creator Budget",
            type: "currency",
            value: displayedCampaign?.creatorBudget,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
            editing: true,
          },
          {
            label: "Funded",
            type: "currency",
            value: displayedCampaign?.fundedAmount,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
        ],
      },
      {
        role: "section",
        label: "Accounting",
        fields: [
          {
            label: "SOW Amount",
            type: "currency",
            fieldKey: "sowAmount",
            editing: true,
          },
          {
            label: "Invoiced",
            type: "currency",
            value: displayedCampaign?.invoicedAmount,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
          {
            label: "Paid to Creators",
            type: "currency",
            value: displayedCampaign?.amountPaidToCreators,
            hideCondition: (values) => {
              return !values?.enableAccountSegmentation;
            },
          },
        ],
      },
      // {
      //   role: "section",
      //   label: "Content Approval",
      //   fields: [
      //     {
      //       label: "Enabled",
      //       value: displayedCampaign?.allowContentApproval,
      //       type: "bool",
      //       writeable: false,
      //       CustomRenderer: BoolIndicator,
      //       cellRendererParams: {
      //         type: "icon",
      //         value: displayedCampaign?.allowContentApproval,
      //       },
      //     },
      //     {
      //       label: "Total Posts",
      //       value: "// TODO: content counts",
      //       type: "number",
      //     },
      //     {
      //       label: "Action Required",
      //       CustomRenderer: () => {
      //         // TODO: link to filtered content
      //         if (!displayedCampaign?.allowContentApproval) {
      //           return <span>No Action Required</span>;
      //         }
      //         return (
      //           <Button color="warning" tag={Link} to={"/content"}>
      //             <ExclamationTriangleIcon
      //               height={20}
      //               width={20}
      //               className="me-2"
      //             />
      //             Review Posts
      //           </Button>
      //         );
      //       },
      //     },

      //     // {
      //     //   label: "Posts Awaiting Admin Action",
      //     //   value:
      //     //     displayedAssignment?.__contentCounts?.awaitingApproval
      //     //     + displayedAssignment?.__contentCounts?.awaitingLinkApproval,
      //     //   type: "number",
      //     // },
      //   ],
      // },
    ],
  };
};

export const campaignCreatorAssignmentDataDefs = (displayedAssignment) => {
  return {
    header: {
      type: "string",
      label: "Creator Assignment",
      value: displayedAssignment?.user_id?.name,
      editing: false,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            type: "id",
            label: "Assignment ID",
            fieldKey: "_id",
            editing: false,
          },
          {
            label: "Assignment Status",
            creatorVisible: true,
            fieldKey: "status",
            type: "string",
            editing: {
              required: false,
            },
            CustomRenderer: () => (
              <Badge pill color="secondary">
                {displayedAssignment?.status}
              </Badge>
            ),
          },
          {
            label: "Custom Handle",
            fieldKey: "agencyCreatorName",
            type: "string",
            editing: {
              required: false,
            },
          },
          {
            label: "Email",
            creatorVisible: true,
            fieldKey: "user_id.email",
            type: "string",
            editing: false,
            CustomRenderer: EmailField,
            componentProps: {
              email: displayedAssignment?.user_id?.email,
              isVerified: displayedAssignment?.user_id?.emailVerified,
            },
          },
          {
            label: "Phone",
            creatorVisible: true,
            fieldKey: "user_id.phone",
            type: "string",
            editing: false,
          },
          {
            label: "Country",
            creatorVisible: true,
            fieldKey: "user_id.country",
            type: "string",
            editing: false,
          },
          {
            label: "Agency/Creator",
            fieldKey: "user_id.isAgency",
            type: "boolean",
            editing: false,
            CustomRenderer: WarningBoolIndicator,
            // TODO: custom select input
            componentProps: {
              value: displayedAssignment?.user_id?.isAgency,
              truthyText: "Agency",
              falsyText: "Creator",
              type: "icon",
            },
          },
          {
            label: "",
            value: displayedAssignment?.user_id?._id,
            type: "link",
            editing: {
              hidden: true,
            },
            CustomRenderer: () => (
              <Link
                to={`/creators/${
                  displayedAssignment?.user_id?._id
                  || displayedAssignment?.user_id
                }`}
                className="small"
              >
                View full Creator Details...
              </Link>
            ),
          },
          {
            label: "Remove from Campaign",
            fieldKey: "__canRemoveCreatorAssignment", // not a real key, just a unique placeholder
            type: "bool",
            hidden: true,
            editing: {
              hidden: false,
              CustomRenderer: memo(RemoveCreatorAssignmentButton),
              componentProps: {
                assignment: displayedAssignment,
              },
            },
          },
        ],
      },
      {
        role: "section",
        label: "Balances",
        fields: [
          {
            label: "Rate",
            creatorVisible: true,
            fieldKey: "rate",
            type: "currency",
            editing: true,
          },
          // {
          //   label: "Rate per Post",
          //   fieldKey: "ratePerPost",
          //   type: "currency",
          //   editing: true,
          // },
          {
            label: "Available",
            creatorVisible: true,
            fieldKey: "availableBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Hold",
            creatorVisible: true,
            fieldKey: "holdBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Paid",
            creatorVisible: true,
            fieldKey: "paidBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Expected Pay Date",
            creatorVisible: true,
            fieldKey: "nextPayoutDate",
            type: "date",
            editing: true,
          },
        ],
      },
    ],
  };
};

// Renders a button that triggers a modal to create a new campaign

import { useCallback, useState } from "react";

import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import { CreateCampaignModal } from "./CreateCampaignModal";

export const CreateCampaignModalButton = ({
  initialBrand_id = null,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="create-campaign">
      <Button
        color="dark"
        className="ms-3 d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Create Campaign
      </Button>
      <CreateCampaignModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        initialBrand_id={initialBrand_id}
      ></CreateCampaignModal>
    </div>
  );
};

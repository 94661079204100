import { Outlet } from "react-router-dom";

import { CreatorsGrid } from "features/creators/components";

export function CreatorsListPage() {
  return (
    <section className="creators-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Creators List</h1>
        </div>
        <div className="main-page__body">
          <CreatorsGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
}

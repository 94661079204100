import { useState, useEffect } from "react";

import clsx from "clsx";

/**
 * Renders a tab pane which defers rendering until it is activated.
 *
 */
export const TabPane = ({ active = false, children }) => {
  const [rendered, setRendered] = useState(active);

  useEffect(() => {
    if (active && !rendered) {
      setRendered(true);
    }
  }, [active, rendered]);

  if (!rendered) return null;

  const classes = clsx("tab-pane", "h-100", active && "show active");

  return <div className={classes}>{children}</div>;
};

import { useCallback, useEffect } from "react";

import autoSizeAll from "components/DataGrid/helpers/autoSizeAll";
import debounce from "helpers/debounce";

/**
 * Hook that listens to window resize events and automatically resizes columns if enabled.
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @param {Object} colApi - The column API object from ag-Grid.
 * @param {boolean} autoSizeEnabled - Flag indicating whether auto-sizing is enabled.
 * @param {boolean} autoSizeIgnoreHeaders - Flag indicating whether to ignore headers when auto-sizing.
 * @returns {object} - Object containing the onFirstDataRendered event handler.
 */
export const useAutoSizeCols = (
  gridApi,
  colApi,
  autoSizeEnabled,
  autoSizeIgnoreHeaders,
  visible,
) => {
  useEffect(() => {
    if (!gridApi || !colApi) return;
    if (visible) {
      autoSizeAll(gridApi, colApi, autoSizeIgnoreHeaders);
    }
  }, [autoSizeIgnoreHeaders, colApi, gridApi, visible]);

  const handleResize = useCallback(() => {
    if (!gridApi || !colApi) return;
    if (autoSizeEnabled) {
      autoSizeAll(gridApi, colApi, autoSizeIgnoreHeaders);
    }
  }, [autoSizeEnabled, autoSizeIgnoreHeaders, colApi, gridApi]);

  useEffect(() => {
    const debouncedResizeHandler = debounce(handleResize, 200);
    window.addEventListener("resize", debouncedResizeHandler);
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, [autoSizeEnabled, autoSizeIgnoreHeaders, handleResize]);

  return {
    onFirstDataRendered: handleResize,
  };
};

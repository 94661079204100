import { createRequestActionTypes } from "../../../actions";

export const CREATE_ONE_CAMPAIGN_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaign`;
export const UPDATE_ONE_CAMPAIGN_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaign`;
export const READ_MANY_CAMPAIGNS_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaigns`;
export const READ_ONE_CAMPAIGN_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaign`;
export const EXPORT_CAMPAIGN_URL = `${process.env.REACT_APP_API_URL}/api/v1/campaign/csv`;
export const READ_MANY_CAMPAIGN_CREATOR_ASSIGNMENTS_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignments`;
export const UPDATE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment`;
export const DELETE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment/:_id`;
export const CREATE_ONE_MILESTONE_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment/addMilestone`;
export const UPDATE_ONE_MILESTONE_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment/editMilestone`;
export const CREATE_ONE_TRANSFER_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment/createTransfer`;
export const CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_URL = `${process.env.REACT_APP_API_URL}/api/v1/assignment`;

export const GET_CAMPAIGNS_ERROR = "Error getting campaigns";
export const GET_CAMPAIGN_ERROR = "Error getting campaign";
export const UPDATE_CAMPAIGN_ERROR = "Error updating campaign";
export const CREATE_CAMPAIGN_ERROR = "Error creating campaign";
export const SAVE_CAMPAIGN_ERROR = "Error saving campaign";
export const SAVE_CAMPAIGN_SUCCESS = "Saved campaign successfully";
export const EXPORT_CAMPAIGN_ERROR = "Error exporting campaign";
export const GET_CAMPAIGN_CREATOR_ASSIGNMENTS_ERROR
  = "Error getting creator assignment(s) for campaign";
export const SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_ERROR
  = "Error saving creator assignment(s) for campaign";
export const SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_SUCCESS
  = "Saved assignment successfully";
export const CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS
  = "Assigned creator to campaign successfully";
export const CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR
  = "Error assigning creator to campaign";
export const DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS
  = "Removed creator from campaign successfully";
export const DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR
  = "Error removing creator from campaign";
export const SAVE_MILESTONE_ERROR = "Error saving milestone";
export const SAVE_MILESTONE_SUCCESS = "Saved milestone successfully";
export const PAY_MILESTONE_ERROR = "Error submitting payment";
export const PAY_MILESTONE_SUCCESS = "Payment submitted successfully";

export const campaignsActionTypes = {
  getCampaigns: createRequestActionTypes("GET_CAMPAIGNS"),
  getCampaign: createRequestActionTypes("GET_CAMPAIGN"),
  exportCampaign: createRequestActionTypes("EXPORT_CAMPAIGN"),
  getCampaignCreatorAssignments: createRequestActionTypes(
    "GET_CAMPAIGN_CREATOR_ASSIGNMENTS",
  ),
  updateCampaignCreatorAssignment: createRequestActionTypes(
    "UPDATE_CAMPAIGN_CREATOR_ASSIGNMENT",
  ),
  createCampaignCreatorAssignment: createRequestActionTypes(
    "CREATE_CAMPAIGN_CREATOR_ASSIGNMENT",
  ),
  removeCampaignCreatorAssignment: createRequestActionTypes(
    "REMOVE_CAMPAIGN_CREATOR_ASSIGNMENT",
  ),
  createCampaign: createRequestActionTypes("CREATE_CAMPAIGN"),
  updateCampaign: createRequestActionTypes("UPDATE_CAMPAIGN"),
  createMilestone: createRequestActionTypes("CREATE_MILESTONE"),
  updateMilestone: createRequestActionTypes("UPDATE_MILESTONE"),
  makePayment: createRequestActionTypes("MAKE_PAYMENT"),
  changeDisplayedCampaign: createRequestActionTypes(
    "CHANGE_DISPLAYED_CAMPAIGN",
  ),
  changeDisplayedAssignment: createRequestActionTypes(
    "CHANGE_DISPLAYED_ASSIGNMENT",
  ),
};

export const campaignStatusDict = {
  active: {
    label: "Active",
    color: "success",
  },
  completed: {
    label: "Completed",
    color: "",
  },
  paused: {
    label: "Paused",
    color: "danger",
  },
  waiting: {
    label: "Waiting",
    color: "warning",
  },
};

import { useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { Button, Label } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";

import { apiSlice } from "app/apiSlice";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { Creator } from "features/creators/components/Creator";

export function CreatorPage() {
  const { creator_id } = useParams();
  const { data } = apiSlice.useGetCreatorQuery({ _id: creator_id });
  const displayedCreator = data?.result;

  const [isEditing, setIsEditing] = useState(false);

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div className="creator-page">
      <Panel key={creator_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/creators"} className="my-1">
          <h1 className="fs-2 d-flex align-items-center">
            <Label className="text-muted small">Creator:</Label>
            {displayedCreator?.name}
            {displayedCreator?._id && (
              <Button
                color="secondary"
                outline
                size="sm"
                className="d-flex align-items-center ms-3"
                onClick={() => setIsEditing(true)}
              >
                <PencilIcon height={20} width={20} />
              </Button>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split="vertical">
          <Creator
            creator_id={creator_id}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <Outlet />
        </PanelBody>
      </Panel>
    </div>
  );
}

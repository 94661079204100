import { isValidElement } from "react";

import { Nav, NavItem, NavLink } from "reactstrap";

import Tab from "./Tab";

/**
 * @typedef {import('react').ReactElement} ReactElement
 */

/**
 * @deprecated Use updated NavTabs component instead on pages with the new route structure.
 *
 * A component that renders a tabbed navigation bar.
 * @param {Object} props - The props object.
 * @param {string|number} props.activeTabId - The index of the currently active tab.
 * @param {function} props.setActiveTabId - A function to set the active tab index.
 * @param {Object[]} props.tabs - An array of objects representing the tabs to render.
 * @param {string|number} props.tabs.id - The ID of the tab.
 * @param {string} props.tabs.label - The label of the tab.
 * @param {React.ElementType} props.tabs.PaneComponent - React component for the tab pane.
 * @param {Object} [props.tabs.paneComponentProps] - An optional object of props to pass to the pane component.
 * @param {React.ComponentType} [props.tabs.TabRowActionComponent] - An optional component to render next to the tabs.
 * @param {string|ReactElement} [props.title] - An optional title to display before the tabs.
 * @returns {JSX.Element} - The rendered component.
 */
const TabbedNav = ({
  activeTabId,
  setActiveTabId,
  tabs = [],
  title = undefined, // optional. can be string or react element
}) => {
  const activeId = activeTabId ?? 0;
  return (
    <>
      <Nav tabs className="d-flex align-items-center">
        {title
          && (isValidElement(title) ? (
            <>{title}</>
          ) : (
            <h2 className="fs-4 m-0 me-3">{title}</h2>
          ))}
        {tabs.map(({ id, label }, index) => (
          <NavItem key={index}>
            <NavLink
              active={activeId === id}
              tag={Tab}
              tabId={id}
              activeTabId={activeId}
              setActiveTabId={(n) => setActiveTabId(n)}
            >
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {tabs?.map(({ id, TabRowActionComponent }, _index) => {
        if (!activeTabId || id !== activeTabId) return null;
        if (!TabRowActionComponent) return null;
        return (
          <div key={id} className="tab-row-action">
            <TabRowActionComponent />
          </div>
        );
      })}
    </>
  );
};

export default TabbedNav;

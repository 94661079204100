import { apiSlice } from "app/apiSlice";

/**
 * PreloadCreators component.
 *
 * @component Preloading creators readmany because it takes forever. making it a component for lazy conditional hook loading.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} [props.children] - The child components.
 * @returns {React.ReactNode} The rendered component.
 */

export const PreloadCreators = ({ children }) => {
  // Pre-load creators
  // TODO: have a more permanent solution for the slow creators readMany
  const { data: _data } = apiSlice.useGetCreatorsQuery(
    {},
    {
      refetchOnMountOrArgChange: 60,
    },
  );
  return <>{children && children}</>;
};

import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useInviteBrandUserMutation } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";

import { brandUsersDataDefs } from "../constants";

/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  // TODO: unique validation messages
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

function CreateBrandUserModal({
  modalOpen,
  toggleModal,

  ...props
}) {
  const { brand_id } = useParams();
  const [createBrandUser, { isLoading }] = useInviteBrandUserMutation();

  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      try {
        await createBrandUser({ brand_id, ...values }).unwrap();
        toggleModal();
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    },
    [brand_id, createBrandUser, toggleModal],
  );

  const initialValues = getInitialValues(brandUsersDataDefs, {});

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-brand-user-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {isLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Invite Brand User</ModalHeader>
          <ModalBody>
            {brandUsersDataDefs?.map((fieldDef) => {
              return (
                <div className="mb-2" key={fieldDef?.fieldKey}>
                  <LabelledData
                    data={initialValues}
                    definition={fieldDef}
                    isEditing={true}
                    editingOnly={true}
                  />
                </div>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Send Invite
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

export default CreateBrandUserModal;

import { useState } from "react";

import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useGetAssignmentQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import Loader from "components/Loader";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { showToast } from "components/Toasts/helpers/showToast";
import MilestoneModal from "features/milestones/MilestoneModal";
import { milestonesColDefs } from "features/milestones/constants/milestonesColDefs";

// import "./index.scss";

// ! deprecated?
const Milestones = ({ assignment_id, ...props }) => {
  const [activeMilestone_id, setActiveMilestone_id] = useState(null);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
  const { milestones, error, isLoading } = useGetAssignmentQuery(
    { _id: assignment_id },
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        milestones: data?.milestones?.map((milestone) => {
          return {
            ...milestone,
            assignment_id: data._id,
          };
        }),
        error,
        isLoading,
      }),
      skip: !assignment_id,
    },
  );

  if (!assignment_id) {
    console.error("No assignment_id provided to Milestones component.");
    return null;
  }
  if (error) {
    showToast({
      type: "error",
      message: "Error fetching milestones.",
    });
  }

  const handleMilestoneClick = ({ _id }) => {
    setActiveMilestone_id(_id);
    setMilestoneModalOpen(!!_id);
  };

  const handleClickCreateMilestone = () => {
    setActiveMilestone_id(null);
    setMilestoneModalOpen(true);
  };

  return (
    <section className="milestones">
      {isLoading && <Loader />}
      <Panel overlay={"expanded"}>
        <PanelHeader unpadded={true}>
          <h5>Assignment Milestones</h5>
        </PanelHeader>
        <PanelBody borders={false}>
          <DataGrid
            gridId="milestones"
            columnDefs={milestonesColDefs}
            autoSizeIgnoreHeaders={false}
            rowData={milestones}
            loading={isLoading}
            actionClick={handleMilestoneClick}
            actionText={"View"}
            borders={true}
            appliedFilters={true}
            quickFilter={true}
            height="100%"
            HeaderActionElem={
              <Button
                color="dark"
                className="ms-3 d-flex align-items-center"
                // TODO: onclick open new milestone modal
                onClick={handleClickCreateMilestone}
              >
                <PlusIcon height={20} width={20} className="me-2" />
                New Milestone
              </Button>
            }
          />
        </PanelBody>
      </Panel>
      <MilestoneModal
        milestone_id={activeMilestone_id}
        assignment_id={assignment_id}
        isOpen={milestoneModalOpen}
        setIsOpen={setMilestoneModalOpen}
      />
    </section>
  );
};

export default Milestones;

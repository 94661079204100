import { memo } from "react";

import TopupStatusRenderer from "components/DataGrid/customRenderers/TopupStatusRenderer";
import { CampaignLinkCellRenderer } from "components/DataGrid/components";

export const topupsColDefs = [
  { headerName: "id", field: "id", hide: true },
  {
    headerName: "Type",
    field: "topupType",
    type: "text",
    valueFormatter: (params) => {
      if (!params?.value) {
        return "";
      }
      return params?.value?.charAt(0)?.toUpperCase() + params?.value?.slice(1);
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    type: "currency",
  },
  {
    headerName: "Available",
    field: "dateAvailable",
    type: "date",
  },
  // {
  //   headerName: "Status",
  //   field: "status",
  //   cellRenderer: memo(TopupStatusRenderer),
  // },
  {
    headerName: "Campaign",
    field: "campaign_id",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "campaignName",
    },
    filterValueGetter: (params) => {
      // Makes the campaign's name and ID searchable
      if (!params.data?.campaignName) {
        return "";
      }
      return `${params.data?.campaignName} ${params.data?.campaign_id}`;
    },
  },
  {
    headerName: "Campaign Short ID",
    field: "campaign_id",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "shortId",
    },
    filterValueGetter: (params) => {
      return params.data?.shortId;
    },
  },
  {
    headerName: "Department",
    field: "department",
    type: "text",
    valueGetter: (params) => {
      let text = "";
      if (params.data?.department === "managed") {
        text = "Managed Services";
      }
      if (params.data?.department === "talent") {
        text = "Talent";
      }
      return text;
    },
  },
  {
    headerName: "Description",
    field: "description",
    type: "text",
    // TODO: formatter
  },
  // {
  //   headerName: "Brand",
  //   field: "brand_id",
  //   // TODO: brand link
  // },

  {
    headerName: "Created",
    field: "dateCreated",
    type: "date",
    sort: "desc",
    hide: true,
  },
];

import CustomSelect from "components/Forms/CustomSelect";

const DeliverablePlatformSelect = ({ ...props }) => {
  // const [_field, _meta, helpers] = useField("network");

  const options = [
    {
      value: "instagram",
      label: "Instagram",
    },
    {
      value: "twitter",
      label: "Twitter",
    },
    {
      value: "tiktok",
      label: "TikTok",
    },
    {
      value: "youtube",
      label: "YouTube",
    },
    {
      // TODO: text input if other
      value: "other",
      label: "Other",
    },
  ];

  return (
    <CustomSelect
      name="network"
      options={options}
      // size={size}
      {...props}
    />
  );
};

export default DeliverablePlatformSelect;

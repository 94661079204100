import {
  AssignmentMilestones,
  AssignmentTransfers,
} from "features/assignments/components";

export const assignmentTabOptions = [
  {
    label: "Milestones",
    id: "milestones",
    Pane: AssignmentMilestones,
    to: "../milestones",
  },
  {
    label: "Transfers",
    id: "transfers",
    Pane: AssignmentTransfers,
    to: "../transfers",
  },
  // TODO: agreements tab
  // {
  //   label: "Agreements",
  //   id: "agreements",
  //   Pane: AssignmentTransfersGrid,
  //   to: "../agreements",
  // },
];

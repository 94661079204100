import { combineReducers } from "@reduxjs/toolkit";

import { apiSlice } from "app/apiSlice";
import loginReducer from "modules/Login/reducers";

import brandsReducer from "../modules/Brands/reducers";
import campaignsReducer from "../modules/Campaigns/reducers";
import contentQueueReducer from "../modules/ContentQueue/reducers";
import creatorsReducer from "../modules/Creators/reducers";
import dashboardReducer from "../modules/Dashboard/reducers";

import dirtyFormReducer from "./dirtyFormReducer";
import loadingReducer from "./loadingReducer";

export default combineReducers({
  loading: loadingReducer,
  dirtyForm: dirtyFormReducer,
  brands: brandsReducer,
  contentQueue: contentQueueReducer,
  creators: creatorsReducer,
  campaigns: campaignsReducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

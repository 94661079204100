import { connect } from "react-redux";

import RemoveAssignmentButton from "components/Buttons/RemoveAssignmentButton";
import { removeCreatorCampaignAssignment } from "modules/Creators/actions";

const RemoveCampaignAssignmentButton = ({
  assignment,

  removeCreatorCampaignAssignment,
}) => {
  let canDelete = true;
  const cantDeleteReasons = [];
  if (assignment?.paidBalance !== 0) {
    canDelete = false;
    cantDeleteReasons.push("Paid balance is not 0.");
  }
  if (assignment?.availableBalance !== 0) {
    canDelete = false;
    cantDeleteReasons.push("Available balance is not 0.");
  }
  // check if assignment has any milestones that have been completed
  if (assignment?.milestones?.length > 0) {
    const completedMilestones = assignment.milestones.filter(
      (milestone) => milestone.isComplete,
    );
    if (completedMilestones.length > 0) {
      canDelete = false;
      cantDeleteReasons.push("Has completed milestones.");
    }
  }
  return (
    <RemoveAssignmentButton
      assignment={assignment}
      canDelete={canDelete}
      cantDeleteReasons={cantDeleteReasons}
      onRemove={removeCreatorCampaignAssignment}
    />
  );
};

const mapStateToProps = (_state) => {
  return {};
};
export default connect(mapStateToProps, {
  removeCreatorCampaignAssignment,
})(RemoveCampaignAssignmentButton);

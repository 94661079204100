import { Button } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { renderFilterCondition } from "components/DataGrid/helpers/renderFilterCondition";
import { titleCaseFormatter } from "helpers/formatters";

/**
 * Renders a filter chip component.
 * @param {Object} props - The component props.
 * @param {string} [props.colName] - Column name.
 * @param {string} [props.field] - The filter field name.
 * @param {Object} [props.condition] - The filter condition object.
 * @param {number} [props.conditionIndex] - The index of the condition.
 * @param {string} [props.operator] - Operator (AND/OR).
 * @param {Function} [props.handleRemoveFilter] - The function to call to remove the filter.
 * @returns {JSX.Element} - The filter chip component.
 */
const FilterChip = ({
  colName,
  field,
  condition,
  conditionIndex,
  operator,
  handleRemoveFilter,
}) => {
  const types = (() => {
    if (condition) {
      return {
        className: "applied-filter-chips__chip--condition",
        content: renderFilterCondition(condition),
      };
    }
    if (operator) {
      return {
        className: "applied-filter-chips__chip--operator",
        content: operator,
      };
    }
    return {
      className: "applied-filter-chips__chip--field",
      content: colName || titleCaseFormatter(field),
    };
  })();

  const title = condition ? "Remove filter condition" : null;

  return (
    <Button
      title={title}
      disabled={!condition}
      onClick={() => {
        if (condition) {
          handleRemoveFilter(field, conditionIndex);
        }
      }}
      className={`applied-filter-chips__chip ${types?.className}`}
    >
      {types?.content}
      {condition && (
        <XMarkIcon
          className="remove-filter-x"
          height={14}
          width={14}
          strokeWidth={2}
        />
      )}
    </Button>
  );
};

export default FilterChip;

import { Link, NavLink as RouterNavLink } from "react-router-dom";
import { Navbar, Nav, NavItem, NavLink, Button } from "reactstrap";

import "./index.scss";
import ProtectedRender from "components/ProtectedRender";

const NavbarHeader = (props) => {
  return (
    <header {...props}>
      <Navbar
        color="light border"
        // @ts-ignore
        full={"true"}
      >
        <Nav navbar>
          <ProtectedRender requiredPermissions={["dashboard"]}>
            <NavItem>
              <NavLink to="/dashboard" tag={RouterNavLink}>
                Dashboard
              </NavLink>
            </NavItem>
          </ProtectedRender>
          <NavItem>
            <NavLink to="/brands" tag={RouterNavLink}>
              Brands
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/campaigns" tag={RouterNavLink}>
              Campaigns
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/creators" tag={RouterNavLink}>
              Creators
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/assignments" tag={RouterNavLink}>
              Assignments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/milestones" tag={RouterNavLink}>
              Milestones
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/transfers" tag={RouterNavLink}>
              Transfers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/invoices" tag={RouterNavLink}>
              Invoices
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink to="/agreements" tag={RouterNavLink}>
              Agreements
            </NavLink>
          </NavItem> */}
        </Nav>
        <Button color="danger" outline size="sm" tag={Link} to={"/logout"}>
          Logout
        </Button>
      </Navbar>
    </header>
  );
};

export default NavbarHeader;

import {
  BrandBrandUsers,
  BrandAssignments,
  BrandCampaigns,
  BrandTransfers,
  BrandMilestones,
  BrandInvoices,
} from "features/brands";
import BrandUserAssignment from "modules/Brands/components/BrandUserAssignment";

export const brandTabOptions = [
  {
    label: "Campaigns",
    id: "campaigns",
    Pane: BrandCampaigns,
    // * this method of specifying `to` value only works if route is set up to use dynamic params to track the active tab.
    to: "../campaigns",
  },
  {
    label: "Creator Assignments",
    id: "creators",
    Pane: BrandAssignments,
    to: "../creators",
  },
  {
    label: "Milestones",
    id: "milestones",
    Pane: BrandMilestones,
    to: "../milestones",
  },
  {
    label: "Transfers",
    id: "transfers",
    Pane: BrandTransfers,
    to: "../transfers",
  },
  {
    label: "Invoices",
    id: "transactions",
    Pane: BrandInvoices,
    to: "../transactions",
  },
  {
    label: "Users",
    id: "users",
    Pane: BrandBrandUsers,
    to: "../users",
    // TODO: @segmentation brand users single view
    Subview: BrandUserAssignment,
  },
];

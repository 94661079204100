import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { apiSlice } from "app/apiSlice";
import { errorLoggerMiddleware } from "app/errorLoggerMiddleware";

import rootReducer from "./reducers";

const persistConfig = {
  key: "creator-portal",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["login"],
  blacklist: ["loading"],
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.NODE_ENV !== "production"
    || process.env.REACT_APP_STAGING === "true",
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   // Ignore action types from redux-persist as per https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    })
      .concat(apiSlice.middleware)
      .concat(errorLoggerMiddleware)
      .concat(thunk),
});
export const persistor = persistStore(store);

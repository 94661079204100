import { memo } from "react";

import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import { dateFormatter } from "helpers/formatters";

export const assignmentsDueColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Creator",
    children: [
      {
        // TODO: display name/name override
        headerName: "Name",
        field: "user.name",
        type: "text",
        width: 200,
      },
      {
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
        headerName: "Email",
        width: 200,
        field: "email",
        type: "text",
      },
    ],
  },
  {
    headerName: "Campaign",
    children: [
      // {
      //   // TODO: brand info not currently populated
      //   headerName: "Brand",
      //   field: "brand_id.name",
      //   type: "text",
      // },
      {
        headerName: "Name",
        field: "campaignName",
        type: "text",
      },
      {
        headerName: "Rate",
        field: "rate",
        type: "currency",
      },
      {
        headerName: "Owed",
        field: "milestoneTotalOwed",
        type: "currency",
      },
      {
        headerName: "Potentially Owed",
        field: "finalPotentiallyOwed",
        type: "currency",
      },
      {
        headerName: "Withdrawable",
        field: "availableBalance",
        type: "currency",
      },
      {
        headerName: "Cashed Out",
        type: "currency",
        field: "paidBalance",
      },
      {
        headerName: "Oldest Due Date",
        field: "oldestDue",
        type: "date",
        sort: "asc",
      },
    ],
  },
];

export const milestonesUpcomingColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Creator",
    children: [
      {
        // TODO: display name/name override
        headerName: "Name",
        field: "creatorName",
        type: "text",
        width: 250,
      },
      {
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
        headerName: "Email",
        width: 250,
        field: "email",
        type: "text",
      },
    ],
  },
  {
    headerName: "Milestone",
    children: [
      {
        headerName: "Name",
        field: "name",
        type: "text",
      },
      {
        headerName: "Payment Date",
        field: "nextPaymentDate",
        type: "date",
        sort: "asc",
        comparator: (a, b, nodeA, nodeB, isDescending) => {
          // sort null values to the bottom on ascending
          if (a === b) {
            return 0;
          } else if (a === null) {
            return isDescending ? -1 : 1;
          } else if (b === null) {
            return isDescending ? 1 : -1;
          } else {
            return a < b ? -1 : 1;
          }
        },
      },
      {
        headerName: "Rate",
        field: "rate",
        type: "currency",
      },
      {
        headerName: "Potentially Owed",
        field: "holdBalance",
        type: "currency",
      },
      {
        headerName: "Completed?",
        field: "isComplete",
        type: "boolean",
        cellRenderer: memo(BoolCellRenderer),
      },
      {
        headerName: "Release Payment?",
        field: "releasePayment",
        type: "boolean",
        cellRenderer: memo(BoolCellRenderer),
      },
      {
        headerName: "Status/Notes",
        field: "status",
        type: "text",
      },
    ],
  },
  {
    headerName: "Campaign",
    children: [
      // {
      //   // TODO: brand info not currently populated
      //   headerName: "Brand",
      //   field: "brand_id.name",
      //   type: "text",
      // },
      {
        headerName: "Name",
        field: "campaignName",
        type: "text",
      },
      {
        headerName: "Rate",
        field: "assignmentRate",
        type: "currency",
      },
      {
        headerName: "Owed",
        field: "assignmentOwed",
        type: "currency",
      },
      {
        headerName: "Potentially Owed",
        field: "assignmentPotentiallyOwed",
        type: "currency",
      },
      {
        headerName: "Withdrawable",
        field: "assignmentAvailableBalance",
        type: "currency",
      },
      {
        headerName: "Cashed Out",
        type: "currency",
        field: "assignmentPaidBalance",
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
      },
    ],
  },
];

export const milestonesDueColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Creator",
    children: [
      {
        // TODO: display name/name override
        headerName: "Name",
        field: "creatorName",
        type: "text",
        width: 250,
      },
      {
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
        headerName: "Email",
        width: 250,
        field: "email",
        type: "text",
      },
    ],
  },
  {
    headerName: "Milestone",
    children: [
      {
        headerName: "Name",
        field: "name",
        type: "text",
      },
      {
        headerName: "Due Date",
        field: "nextPaymentDate",
        type: "date",
        sort: "asc",
        comparator: (a, b, nodeA, nodeB, isDescending) => {
          // sort null values to the bottom on ascending
          if (a === b) {
            return 0;
          } else if (a === null) {
            return isDescending ? -1 : 1;
          } else if (b === null) {
            return isDescending ? 1 : -1;
          } else {
            return a < b ? -1 : 1;
          }
        },
      },
      {
        headerName: "Rate",
        field: "rate",
        type: "currency",
      },
      {
        headerName: "Owed",
        field: "holdBalance",
        type: "currency",
      },
      // {
      //   headerName: "Release Payment?",
      //   field: "releasePayment",
      //   type: "boolean",
      //   cellRenderer: memo(BoolCellRenderer),
      // },
      {
        headerName: "Status/Notes",
        field: "status",
        type: "text",
      },
    ],
  },
  {
    headerName: "Campaign",
    children: [
      // {
      //   // TODO: brand info not currently populated
      //   headerName: "Brand",
      //   field: "brand_id.name",
      //   type: "text",
      // },
      {
        headerName: "Name",
        field: "campaignName",
        type: "text",
      },
      {
        headerName: "Rate",
        field: "assignmentRate",
        type: "currency",
      },
      {
        headerName: "Owed",
        field: "assignmentOwed",
        type: "currency",
      },
      {
        headerName: "Potentially Owed",
        field: "assignmentPotentiallyOwed",
        type: "currency",
      },
      {
        headerName: "Withdrawable",
        field: "assignmentAvailableBalance",
        type: "currency",
      },
      {
        headerName: "Cashed Out",
        type: "currency",
        field: "assignmentPaidBalance",
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
      },
    ],
  },
];

export const assignmentsUpcomingColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Creator",
    children: [
      {
        // TODO: display name/name override
        headerName: "Name",
        field: "user.name",
        type: "text",
        width: 200,
      },
      {
        // TODO: styling will break if user moves columns
        cellStyle: {
          borderRight: "var(--ag-borders-critical) var(--ag-border-color)",
        },
        headerName: "Email",
        width: 200,
        field: "email",
        type: "text",
      },
    ],
  },
  {
    headerName: "Campaign",
    children: [
      // {
      //   // TODO: brand info not currently populated
      //   headerName: "Brand",
      //   field: "brand_id.name",
      //   type: "text",
      // },
      {
        headerName: "Name",
        field: "campaignName",
        type: "text",
      },
      {
        headerName: "Rate",
        field: "rate",
        type: "currency",
      },
      {
        headerName: "Owed",
        field: "milestoneTotalOwed",
        type: "currency",
      },
      {
        headerName: "Potentially Owed",
        field: "finalPotentiallyOwed",
        type: "currency",
      },
      {
        headerName: "Withdrawable",
        field: "availableBalance",
        type: "currency",
      },
      {
        headerName: "Cashed Out",
        type: "currency",
        field: "paidBalance",
      },
      {
        headerName: "Next Due Date",
        field: "soonestUpcomingDue",
        type: "date",
        sort: "asc",
        comparator: (a, b, nodeA, nodeB, isDescending) => {
          // sort null values to the bottom on ascending
          if (a === b) {
            return 0;
          } else if (a === null) {
            return isDescending ? -1 : 1;
          } else if (b === null) {
            return isDescending ? 1 : -1;
          } else {
            return a < b ? -1 : 1;
          }
        },
      },
    ],
  },
];

export const forecastColDefs = [
  {
    headerName: "Due Date",
    field: "date",
    type: "dateString",
    sortable: false,
    filter: false,
    valueFormatter: (params) => {
      if (params.value === "before") return "Past Due";
      let formatted = dateFormatter(params.value, {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      });
      if (params.node.data.isFullWidthRow) {
        formatted = "Week of " + formatted;
      }
      return formatted;
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    type: "currency",
    sortable: false,
    filter: false,
  },
  {
    headerName: "Payments Due",
    field: "count",
    sortable: false,
    filter: false,
  },
];

import { useParams } from "react-router-dom";

import Milestones from "features/milestones";

const CampaignAssignmentMilestones = () => {
  const { assignment_id } = useParams();

  return <Milestones assignment_id={assignment_id} />;
};

export default CampaignAssignmentMilestones;

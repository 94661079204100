import { useCallback } from "react";

import { connect } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { login } from "./actions";

const validate = (values) => {
  const errors = {};
  if (!values.email) errors.email = "Required";
  if (!values.password) errors.password = "Required";
  return errors;
};

const Login = ({ login }) => {
  const submit = useCallback((values) => login(values), [login]);

  return (
    <Container>
      <Row>
        <Col xs="12" md="6" lg="4" className="mx-auto">
          <Card>
            <CardBody>
              <h1 className="mb-4">Login</h1>
              <Formik
                initialValues={{ email: "", password: "" }}
                validate={validate}
                onSubmit={submit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        invalid={!!(errors.email && touched.email)}
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.email}
                      />
                      <FormFeedback>{errors?.email?.toString()}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.password}
                      />
                      <FormFeedback>
                        {errors?.password?.toString()}
                      </FormFeedback>
                    </FormGroup>
                    <Button type="submit">Login</Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (_state) => {
  return {};
};
export default connect(mapStateToProps, {
  login,
})(Login);

import { useCallback } from "react";

import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { showToast } from "components/Toasts/helpers/showToast";
import { campaignTopupDataDefs } from "modules/Dashboard/components/TopupsCard/constants/dataDefs";

/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  // TODO: unique validation messages
  const errors = {};
  if (!values.campaign_id) {
    errors.campaign_id = "Required";
  }
  if (!values.invoiceId) {
    errors.invoiceId = "Required";
  }
  if (!values.description) {
    errors.description = "Required";
  }
  if (!values.amount) {
    errors.amount = "Required";
  }
  if (values.amount <= 0) {
    errors.amount = "Amount must be greater than 0";
  }
  return errors;
};

function CampaignTopupModal({
  modalOpen,
  toggleModal,
  onTopupCreated = undefined,

  ...props
}) {
  const [createTopup, { isLoading }]
    = apiSlice.useCreateCampaignTopupMutation();
  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      try {
        const topup = await createTopup(values).unwrap();
        if (onTopupCreated) onTopupCreated(topup);
        toggleModal();
      } catch (error) {
        showToast({
          type: "error",
          message: `Error topping up. ${error?.data?.message || error?.message}`,
        });
        console.error("Error topping up.", error);
      }
      setSubmitting(false);
    },
    [createTopup, onTopupCreated, toggleModal],
  );

  const initialValues = getInitialValues(campaignTopupDataDefs);

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="campaign-topup-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {isLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Topup Campaign Balance</ModalHeader>
          <ModalBody>
            <div className="labeled-data-container">
              {campaignTopupDataDefs?.map((fieldDef) => {
                return (
                  <LabelledData
                    key={fieldDef?.fieldKey}
                    data={initialValues}
                    definition={fieldDef}
                    isEditing={true}
                    editingOnly={true}
                  />
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

export default CampaignTopupModal;

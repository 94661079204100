import { useState } from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import { SelectCreatorToAssign } from "features/creators/components/SelectCreatorToAssign";

/**
 * @typedef {Object} AssignExistingCreatorsModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} campaign_id - Campaign ID to which creator will be assigned
 */
/**
 * Assign Creator to Campaign Modal
 * @param {AssignExistingCreatorsModalProps} props
 * @returns {JSX.Element}
 */
export const AssignExistingCreatorModal = ({
  campaign_id,
  modalOpen,
  toggleModal,
  ...props
}) => {
  const [selectedCreator_id, setSelectedCreator_id] = useState(null);

  const [createAssignment] = apiSlice.useCreateAssignmentMutation();

  const handleSaveAssignmentClick = async() => {
    try {
      await createAssignment({
        user_id: selectedCreator_id,
        campaign_id,
      }).unwrap();
      toggleModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size="xl"
      toggle={toggleModal}
      className="assign-existing-creator-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      <ModalHeader toggle={toggleModal}>Assign Creator to Campaign</ModalHeader>
      <ModalBody>
        <SelectCreatorToAssign
          setSelectedId={setSelectedCreator_id}
          campaign_id={campaign_id}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          color="dark"
          disabled={!selectedCreator_id}
          onClick={handleSaveAssignmentClick}
        >
          Save Assignment
        </Button>
      </ModalFooter>
    </Modal>
  );
};

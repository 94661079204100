// Renders a button that triggers a modal to create a new milestone

import { useCallback, useState } from "react";

import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";

import MilestoneModal from "features/milestones/MilestoneModal";

export const CreateMilestoneModalButton = ({ assignment_id }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="create-milestone">
      <Button
        color="dark"
        className="ms-3 d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Create Milestone
      </Button>
      <MilestoneModal
        isOpen={modalOpen}
        setIsOpen={toggleModal}
        assignment_id={assignment_id}
      />
    </div>
  );
};

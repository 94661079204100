import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Agreements } from "features/agreements";
import {
  AssignmentAssignmentsPageSection,
  AssignmentPage,
  AssignmentsListPage,
} from "features/assignments";
import {
  BrandAssignmentSection,
  BrandAssignmentsPageSection,
  BrandPage,
  BrandsListPage,
} from "features/brands";
import {
  CampaignAssignmentSection,
  CampaignAssignmentsPageSection,
  CampaignPage,
} from "features/campaigns";
import { CampaignsListPage } from "features/campaigns/pages/CampaignsListPage";
import { CreatorsListPage } from "features/creators";
import { CreatorAssignmentsPageSection } from "features/creators/pages/CreatorAssignmentsListSection";
import { CreatorPage } from "features/creators/pages/CreatorPage";
import { InvoicesListPage } from "features/invoices";
import { MilestonesListPage } from "features/milestones/pages/MilestonesListPage";
import { TransfersListPage } from "features/transfers/pages/TransfersListPage";
import Dashboard from "modules/Dashboard";
import Login from "modules/Login";
import Logout from "modules/Login/Logout";
import {
  PrivatePageLayout,
  ProtectedRouteLayout,
  PublicPageLayout,
} from "routers";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Private Routes */}
        <Route element={<PrivatePageLayout />}>
          <Route
            element={
              <ProtectedRouteLayout requiredPermissions={["dashboard"]} />
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          {/* start new creators routes */}
          {/* <Route path="/creators" element={<CreatorsPage />}>
            <Route path=":creator_id" element={<CreatorPage />}>
              <Route path="campaigns" element={<CreatorCampaigns />}>
                <Route path=":campaign_id" element={<CreatorCampaign />}>
                  <Route
                    path="milestones"
                    element={<CreatorCampaignMilestones />}
                  />
                  <Route
                    path="agreements"
                    element={<CreatorCampaignAgreements />}
                  />
                  <Route
                    path="deliverables"
                    element={<CreatorCampaignDeliverables />}
                  />
                  <Route
                    index // default to milestones
                    element={<Navigate replace to="milestones" />}
                  />
                </Route>
              </Route>
              <Route path="agreements" element={<CreatorAgreements />}>
                <Route path=":agreement_id" element={<CreatorAgreement />} />
              </Route>
              <Route path="deliverables" element={<CreatorDeliverables />}>
                <Route
                  path=":deliverable_id"
                  element={<CreatorDeliverable />}
                />
              </Route>
              <Route path="milestones" element={<CreatorMilestones />}>
                <Route path=":milestone_id" element={<CreatorMilestone />} />
              </Route>
              <Route index element={<Navigate replace to="campaigns" />} />
            </Route>
          </Route> */}
          {/* end new creators routes */}
          <Route path="brands" element={<BrandsListPage />}>
            <Route path=":brand_id" element={<BrandPage />}>
              <Route
                index
                element={<Navigate to="campaigns" replace={true} />}
              />
              <Route
                path=":assignmentsTab"
                element={<BrandAssignmentsPageSection />}
              >
                <Route
                  path=":assignment_id"
                  element={<BrandAssignmentSection />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="campaigns" element={<CampaignsListPage />}>
            <Route path=":campaign_id" element={<CampaignPage />}>
              <Route
                index
                element={<Navigate to="creators" replace={true} />}
              />
              <Route
                path=":assignmentsTab"
                element={<CampaignAssignmentsPageSection />}
              >
                <Route
                  path=":assignment_id"
                  element={<CampaignAssignmentSection />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="creators" element={<CreatorsListPage />}>
            <Route path=":creator_id" element={<CreatorPage />}>
              <Route
                index
                element={<Navigate to="campaigns" replace={true} />}
              />
              <Route
                path=":assignmentsTab"
                element={<CreatorAssignmentsPageSection />}
              />
            </Route>
          </Route>
          <Route path="assignments" element={<AssignmentsListPage />}>
            <Route path=":assignment_id" element={<AssignmentPage />}>
              <Route
                index
                element={<Navigate to="milestones" replace={true} />}
              />
              <Route
                path=":assignmentsTab"
                element={<AssignmentAssignmentsPageSection />}
              />
            </Route>
          </Route>
          <Route path="milestones" element={<MilestonesListPage />}></Route>
          <Route path="transfers" element={<TransfersListPage />}></Route>
          <Route path="invoices" element={<InvoicesListPage />}></Route>
          <Route path="agreements" element={<Agreements />} />
        </Route>
        {/* Public Routes */}
        <Route element={<PublicPageLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
        {/* // TODO: 404 page */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
};

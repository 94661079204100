import { useState } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";

import { milestonesCardTabOptions } from "modules/Dashboard/constants/tabOptions";

import TabbedNav from "../../../../components/TabbedNav";
import TabPanes from "../../../../components/TabbedNav/TabPanes";
import "./index.scss";

const MilestonesCard = ({ ...props }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    milestonesCardTabOptions[0]?.id,
  );
  return (
    <Card className="milestones-card">
      <CardBody>
        <CardTitle>
          <TabbedNav
            activeTabId={activeTabIndex}
            setActiveTabId={setActiveTabIndex}
            tabs={milestonesCardTabOptions}
            title="Milestones/Assignments"
          ></TabbedNav>
        </CardTitle>
      </CardBody>
      <CardBody>
        <TabPanes
          activeTabId={activeTabIndex}
          setActiveTabId={setActiveTabIndex}
          tabs={milestonesCardTabOptions}
        />
      </CardBody>
    </Card>
  );
};

export default MilestonesCard;

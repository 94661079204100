import { useCallback, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

/**
 * A hook that prompts the user with a confirmation message before navigating away from the current page.
 * @param {boolean} when - A boolean value that determines whether to prompt the user with a confirmation message.
 * @param {string} message - The message to display in the confirmation dialog.
 */
export const useConfirmNavigate = (when, message) => {
  const navigate = useNavigate();
  const location = useLocation();

  const block = useCallback(
    () => (when ? window.confirm(message) : true),
    [when, message],
  );

  const handlePopState = useCallback(
    (e) => {
      if (!block()) {
        e.preventDefault();
        navigate(location, { replace: true });
      }
    },
    [block, navigate, location],
  );

  const handleBeforeUnload = useCallback(
    (e) => {
      if (block()) return;
      e.preventDefault();
      e.returnValue = message;
    },
    [block, message],
  );

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handlePopState, handleBeforeUnload]);
};

// Usage in some component
// useConfirmNavigate(someCondition, 'Leaving so soon?');

import { useEffect } from "react";

import { connect } from "react-redux";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import Loader from "components/Loader";
import { currencyFormatter } from "helpers/formatters";
import { getCreatorBalances } from "modules/Dashboard/actions";

const CreatorBalanceCard = ({
  loading,
  creatorBalanceAvailable,
  creatorBalancePaid,
  creatorBalanceHold,
  creatorBalanceEarned,
  creatorBalancePotentiallyOwed,
  creatorBalanceMilestoneHold,
  creatorBalanceMilestonePotentiallyOwed,
  creatorBalanceMilestoneOwed,
  getCreatorBalances,
  ...props
}) => {
  useEffect(() => {
    getCreatorBalances();
  }, [getCreatorBalances]);
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <h2 className="fs-4">Creator Balances</h2>
        </CardTitle>
        {loading && <Loader />}
        <CardText className="dashboard__content__labelled-data">
          <span>
            <span className="small text-muted mb-2">Owed</span>
            {currencyFormatter(creatorBalanceMilestoneOwed)}
          </span>
          <span>
            <span className="small text-muted mb-2">On Hold</span>
            {currencyFormatter(creatorBalancePotentiallyOwed)}
          </span>
          <span>
            <span className="small text-muted mb-2">Withdrawable</span>
            {currencyFormatter(creatorBalanceAvailable)}
          </span>
          <span>
            <span className="small text-muted mb-2">Cashed Out</span>
            {currencyFormatter(creatorBalancePaid)}
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_CREATOR_BALANCES?.pending,
    creatorBalanceAvailable: state.dashboard.creatorBalanceAvailable,
    creatorBalancePaid: state.dashboard.creatorBalancePaid,
    creatorBalanceHold: state.dashboard.creatorBalanceHold,
    creatorBalanceEarned: state.dashboard.creatorBalanceEarned,
    creatorBalancePotentiallyOwed:
      state.dashboard.creatorBalancePotentiallyOwed,
    creatorBalanceMilestoneHold: state.dashboard.creatorBalanceMilestoneHold,
    creatorBalanceMilestonePotentiallyOwed:
      state.dashboard.creatorBalanceMilestonePotentiallyOwed,
    creatorBalanceMilestoneOwed: state.dashboard.creatorBalanceMilestoneOwed,
  };
};
export default connect(mapStateToProps, {
  getCreatorBalances,
})(CreatorBalanceCard);

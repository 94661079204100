import { useMemo } from "react";

import clsx from "clsx";

import { MilestonesGrid } from "features/milestones/components";

export const BrandMilestones = ({ brand_id, className, ...props }) => {
  const classes = clsx("brand-milestones", className);
  const filterParams = useMemo(() => {
    return {
      brand_id: brand_id,
    };
  }, [brand_id]);
  return (
    <MilestonesGrid
      filterParams={filterParams}
      className={classes}
      gridId="brand-milestones"
      excludeDataSources="brand"
    />
  );
};

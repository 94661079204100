import { useMemo } from "react";

import clsx from "clsx";

import { MilestonesGrid } from "features/milestones/components";

export const CreatorMilestones = ({ user_id, className, ...props }) => {
  const classes = clsx("creator-milestones", className);
  const filterParams = useMemo(() => {
    return {
      user_id: user_id,
    };
  }, [user_id]);
  return (
    <MilestonesGrid
      filterParams={filterParams}
      className={classes}
      gridId="creator-milestones"
      excludeDataSources="creator"
    />
  );
};

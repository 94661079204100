import { useMemo } from "react";

import clsx from "clsx";

import { AssignmentsGrid } from "features/assignments";

export const CreatorAssignments = ({ creator_id, className, ...props }) => {
  const classes = clsx("creator-assignments", className);
  const filterParams = useMemo(() => {
    return {
      user_id: creator_id,
    };
  }, [creator_id]);
  return (
    <AssignmentsGrid
      filterParams={filterParams}
      className={classes}
      gridId="creator-assignments"
      excludeDataSources={"creator"}
    />
  );
};

import autoSizeAll from "components/DataGrid/helpers/autoSizeAll";
import debounce from "helpers/debounce";

// TODO: fix bug where column states are being saved even when default or not changed by user. Needs to only save after user changes state

export const usePersistGridState = (
  gridApi,
  colApi,
  gridId,
  autoSizeEnabled,
  autoSizeIgnoreHeaders,
) => {
  const storageKey = `v2-grid-state-${gridId}`;

  const saveGridState = () => {
    // Save grid state to localStorage
    if (!gridApi || !colApi) return;
    console.debug(`Saving ${gridId} grid state to localStorage`);
    const state = colApi.getColumnState();
    const filters = gridApi.getFilterModel();
    const gridState = JSON.stringify({
      state,
      filters,
    });
    localStorage.setItem(storageKey, gridState);
  };

  const debouncedSaveGridState = debounce(saveGridState, 1000);

  const restoreSavedGridState = () => {
    if (!gridApi || !colApi) return;
    try {
      // Load grid state from localStorage
      const savedState = JSON.parse(localStorage.getItem(storageKey));
      if (savedState) {
        console.debug(`Restoring ${gridId} grid state from localStorage`);
        colApi.applyColumnState({
          state: savedState?.state,
          applyOrder: true,
        });
        gridApi.setFilterModel(savedState?.filters);
      }
    } catch (error) {
      console.error(
        `Error loading ${gridId} grid state from localStorage:`,
        error,
      );
    }
  };

  const handleGridStateSync = () => {
    try {
      debouncedSaveGridState();
    } catch (error) {
      console.error(
        `Error saving ${gridId} grid state to localStorage:`,
        error,
      );
    }
  };

  const resetGridState = () => {
    try {
      console.debug(`Resetting ${gridId} grid state`);
      if (!colApi) return;
      colApi.resetColumnState();
      if (autoSizeEnabled) {
        autoSizeAll(gridApi, colApi, autoSizeIgnoreHeaders);
      }
    } catch (error) {
      console.error(`Error resetting ${gridId} grid state:`, error);
    }
  };

  const resetGridFilters = () => {
    try {
      console.debug(`Resetting ${gridId} grid filters`);
      if (!gridApi) return;
      autoSizeAll(gridApi, colApi, false);
    } catch (error) {
      console.error(`Error resetting ${gridId} grid filters:`, error);
    }
  };

  const expandGridColumns = () => {
    try {
      console.debug(`Expanding ${gridId} grid columns`);
      if (!colApi) return;
      // FIXME: when expanding columns on a grid where the grid width is larger than the total column width (after expanding), there is blank space left over to the right of the last column. Instead, check if the grid width is larger than the total column width and if so, size columns to fit instead of auto-sizing.
      colApi.autoSizeAllColumns();
    } catch (error) {
      console.error(`Error expanding ${gridId} grid columns:`, error);
    }
  };

  const shrinkGridColumns = () => {
    try {
      console.debug(`Shrinking ${gridId} grid columns`);
      if (!colApi) return;
      gridApi.sizeColumnsToFit();
    } catch (error) {
      console.error(`Error shrinking ${gridId} grid columns:`, error);
    }
  };

  return {
    onFirstDataRendered: restoreSavedGridState,
    onColumnMoved: handleGridStateSync,
    onColumnResized: handleGridStateSync,
    onColumnVisible: handleGridStateSync,
    onColumnPinned: handleGridStateSync,
    onSortChanged: handleGridStateSync,
    onFilterChanged: handleGridStateSync,
    resetGridState,
    resetGridFilters,
    expandGridColumns,
    shrinkGridColumns,
  };
};

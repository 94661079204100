import { Outlet } from "react-router-dom";

import { InvoicesGrid } from "features/invoices/components";

export function InvoicesListPage() {
  return (
    <section className="Invoices-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Invoices List</h1>
        </div>
        <div className="main-page__body">
          <InvoicesGrid />
        </div>
      </div>
      <Outlet />
    </section>
  );
}

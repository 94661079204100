import { Badge } from "reactstrap";

import { campaignStatusDict } from "../constants";

const CampaignStatusBadge = ({ value, ...props }) => {
  const statusConfig = campaignStatusDict[value];
  if (!statusConfig) return null;
  const badgeColorProps = statusConfig.color
    ? { color: statusConfig.color }
    : {};
  return (
    <>
      {value && (
        <Badge {...badgeColorProps} pill>
          {statusConfig?.label}
        </Badge>
      )}
    </>
  );
};

export default CampaignStatusBadge;

import clsx from "clsx";

import { NavLink } from "components/NavLink";

/**
 * Renders a link to a creator based on the provided creator ID.
 *
 * @param {Object} props - The component props.
 * @param {string} props._id - The ID of the creator.
 * @param {Object} props.data
 * @returns {JSX.Element} The rendered CreatorLink component.
 */
export const CreatorLink = ({ _id, data }) => {
  let text;
  let muted;
  if (!data?.user_id?.name && !data?.name) {
    text = "Can not display creator data.";
  } else {
    text = data.user_id?.name || data.name;
    muted = false;
  }
  const linkClassName = clsx("p-0", {
    "small text-muted": muted,
    "link-accent-dark": !muted,
  });
  return (
    <NavLink className={linkClassName} to={`/creators/${_id}`}>
      {text}
    </NavLink>
  );
};

import { Outlet, useParams } from "react-router-dom";
import { Card, CardHeader } from "reactstrap";

import { NavTabs, TabContent } from "components/NavTabs";
import { brandTabOptions } from "features/brands/constants/tabOptions";

export function BrandAssignmentsPageSection() {
  const { assignmentsTab, brand_id } = useParams();
  return (
    <div className="brand-assignments-page-section d-flex flex-fill position-relative">
      <Card className="border-0 rounded-0 flex-fill" outline body={false}>
        <CardHeader>
          <NavTabs tabs={brandTabOptions} />
        </CardHeader>
        <TabContent
          className="p-2"
          activeTabId={assignmentsTab}
          tabs={brandTabOptions}
          brand_id={brand_id}
        />
      </Card>
      <Outlet />
    </div>
  );
}

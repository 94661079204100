import { memo } from "react";

import {
  AssignmentLinkCellRenderer,
  CampaignLinkCellRenderer,
} from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";
import { CreatorLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/CreatorLinkCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

export const assignmentsColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Assignment",
    dataSource: "assignment",
    children: [
      {
        headerName: "Name",
        field: "_id",
        cellRenderer: memo(AssignmentLinkCellRenderer),
        type: "text",
        initialWidth: 200,
        filterValueGetter: (params) => {
          // Makes the assignment's creator name, campaign name, and assignment ID searchable
          if (!params.data?.user_id?.name || !params.data?.campaign_id?.name) {
            return "";
          }
          return `${params.data?.user_id?.name} ${params.data?.campaign_id?.name} ${params.data?.assignment_id}`;
        },
      },
      {
        headerName: "Department",
        field: "department",
        type: "text",
        valueGetter: (params) => {
          let text = "";
          if (params.data?.department === "managed") {
            text = "Managed Services";
          }
          if (params.data?.department === "talent") {
            text = "Talent";
          }
          return text;
        },
      },
      {
        headerName: "Status",
        field: "status",
        type: "text",
      },
      {
        headerName: "Brand",
        field: "brand_id._id",
        dataSource: "brand",
        cellRenderer: memo(BrandLinkCellRenderer),
        type: "text",
        filterValueGetter: (params) => {
          // Makes the brand's name and ID searchable
          if (!params.data?.brand_id?.name) {
            return "";
          }
          return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
        },
      },
      {
        headerName: "Rate",
        field: "rate",
        type: "currency",
      },
      {
        headerName: "Allocated",
        field: "__allocatedBalance",
        type: "currency",
      },
      {
        headerName: "Unallocated",
        field: "__unallocatedBalance",
        type: "currency",
      },
      {
        headerName: "Hold",
        field: "holdBalance",
        type: "currency",
      },
      {
        headerName: "Withdrawable",
        field: "availableBalance",
        type: "currency",
      },
      {
        headerName: "Paid",
        field: "paidBalance",
        type: "currency",
      },
      {
        headerName: "Expected Pay Date",
        field: "nextPayoutDate",
        type: "date",
      },
    ],
  },
  {
    headerName: "Campaign",
    dataSource: "campaign",
    children: [
      {
        headerName: "Name",
        field: "campaign_id._id",
        type: "text",
        dataSource: "campaign",
        cellRenderer: memo(CampaignLinkCellRenderer),
        filterValueGetter: (params) => {
          // Makes the campaign's name and ID searchable
          if (!params.data?.campaign_id?.name) {
            return "";
          }
          return `${params.data?.campaign_id?.name} ${params.data?.campaign_id?._id}`;
        },
      },
      {
        headerName: "Short ID",
        field: "campaign_id._id",
        type: "text",
        dataSource: "campaign",
        cellRenderer: memo(CampaignLinkCellRenderer),
        cellRendererParams: {
          linkTextKey: "campaign_id?.shortId",
        },
        filterValueGetter: (params) => {
          return params.data?.campaign_id?.shortId;
        },
      },
      {
        headerName: "Department",
        field: "campaign_id.department",
        type: "text",
        valueGetter: (params) => {
          let text = "";
          if (params.data?.department === "managed") {
            text = "Managed Services";
          }
          if (params.data?.department === "talent") {
            text = "Talent";
          }
          return text;
        },
      },
      {
        headerName: "Status",
        field: "campaign_id.status",
        type: "text",
        cellRenderer: memo(CampaignStatusBadge),
      },
      // {
      //   headerName: "Client Login Allowed",
      //   field: "campaign_id.allowClientAccess",
      //   type: "boolean",
      //   cellRenderer: memo(BoolCellRenderer),
      // },
      // {
      //   headerName: "SOW $",
      //   field: "campaign_id.sowAmount",
      //   type: "currency",
      // },
      // {
      //   headerName: "Budget",
      //   type: "currency",
      //   field: "campaign_id.creatorBudget",
      // },
      // {
      //   headerName: "Paid to Creators",
      //   type: "currency",
      //   field: "campaign_id.amountPaidToCreators",
      // },
      {
        headerName: "Start Date",
        field: "campaign_id.startDate",
        type: "date",
      },
      {
        headerName: "End Date",
        field: "campaign_id.endDate",
        type: "date",
      },
      // {
      //   headerName: "Created Date",
      //   field: "campaign_id.created",
      //   type: "date",
      // },
    ],
  },
  {
    headerName: "Creator",
    dataSource: "creator",
    children: [
      // TODO: @coldefs review and add missing creator fields on assignment defs
      {
        headerName: "Name",
        field: "user_id._id",
        dataSource: "creator",
        cellRenderer: memo(CreatorLinkCellRenderer),
        type: "text",
        filterValueGetter: (params) => {
          // Makes the creator's name and ID searchable
          if (!params.data?.user_id?.name) {
            return "";
          }
          return `${params.data?.user_id?.name} ${params.data?.user_id?._id}`;
        },
      },
      {
        headerName: "Custom Handle",
        field: "agencyCreatorName",
        type: "text",
      },
      {
        headerName: "Agency/Creator",
        field: "user_id.isAgency",
        type: "boolean",
        valueGetter: (params) => {
          return params.data?.user_id?.isAgency ? "Agency" : "Creator";
        },
      },
      {
        headerName: "Email",
        field: "user_id.email",
        type: "text",
        flex: 2,
      },
      {
        headerName: "Phone",
        field: "user_id.phone",
        // TODO: @cellrender add phone cell renderer
        type: "text",
      },
      {
        headerName: "Country",
        field: "user_id.country",
        type: "text",
      },
      {
        headerName: "Stripe Onboarding",
        field: "user_id.needsStripeOnboarding",
        type: "boolean",
        valueGetter: (params) => {
          return !params.data.user_id?.needsStripeOnboarding;
        },
        cellRenderer: memo(BoolCellRenderer),
        cellRendererParams: {
          type: "icon",
          truthyText: "Complete",
          falsyText: "Not Completed",
        },
      },
      {
        headerName: "Financial Account",
        field: "user_id.needsStripeFinancialConnection",
        type: "boolean",
        cellDataType: "boolean", // BUG: this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
        valueGetter: (params) => {
          return !params.data.user_id?.needsStripeFinancialConnection;
        },
        cellRenderer: memo(BoolCellRenderer),
        cellRendererParams: {
          type: "icon",
          truthyText: "Linked",
          falsyText: "Not Linked",
        },
      },
      {
        headerName: "Last Login",
        field: "user_id.lastLogin",
        type: "date",
      },
      // {
      //   headerName: "First Login",
      //   field: "user_id.firstLogin",
      //   type: "date",
      // },
      // {
      //   headerName: "Account Disabled",
      //   field: "user_id.disabled",
      //   type: "boolean",
      //   cellRenderer: memo(WarningBoolCellRenderer),
      // },
    ],
  },
];

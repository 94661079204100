import { ModalHeader } from "reactstrap";

import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import { useAgreementModalContext } from "features/agreements/components/Agreement/context/AgreementModalContext";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";

const AgreementModalHeader = () => {
  const { agreementUIStatus } = useAgreementContext();
  const { toggleAgreementModal } = useAgreementModalContext();
  const { agreement } = useAgreementData();

  return (
    <ModalHeader toggle={toggleAgreementModal}>
      {agreementUIStatus === "create" && (
        <span className="bold">Create New Agreement</span>
      )}
      {agreementUIStatus === "config" && (
        <span className="bold">Configure and Send</span>
      )}
      {agreementUIStatus === "edit" && (
        <span className="bold text-muted">
          Edit Agreement -&nbsp;
          <span className="text-body">{agreement?.title}</span>
        </span>
      )}
      {agreementUIStatus === "sign" && (
        <span className="bold text-muted">
          Sign Agreement -&nbsp;
          <span className="text-body">{agreement?.title}</span>
        </span>
      )}
    </ModalHeader>
  );
};

export default AgreementModalHeader;

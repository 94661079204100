import { actionCreator, privateHeaders } from "actions";

import { showToast } from "components/Toasts/helpers/showToast";
import {
  CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS,
  CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
  DELETE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
  GET_CREATORS_ERROR,
  GET_CREATOR_ASSIGNMENTS_ERROR,
  GET_CREATOR_ERROR,
  INVITE_CREATOR_ERROR,
  INVITE_CREATOR_SUCCESS,
  INVITE_CREATOR_URL,
  READ_MANY_CREATORS_URL,
  READ_MANY_CREATOR_CAMPAIGN_ASSIGNMENTS_URL,
  READ_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
  READ_ONE_CREATOR_URL,
  REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_ERROR,
  REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS,
  SAVE_CREATOR_ASSIGNMENT_ERROR,
  SAVE_CREATOR_ASSIGNMENT_SUCCESS,
  SAVE_CREATOR_ERROR,
  SAVE_CREATOR_SUCCESS,
  UPDATE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
  UPDATE_ONE_CREATOR_URL,
  creatorsActionTypes,
} from "modules/Creators/constants";

/**
 * Action creator function that creates a new creator on the server.
 * @param {Object} creator - An object containing the details of the creator to be created.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const inviteCreator = (creator) => {
  return async(dispatch) => {
    dispatch(actionCreator(creatorsActionTypes.inviteCreator.REQUEST));
    const url = new URL(INVITE_CREATOR_URL);
    const params = url.searchParams;
    params.append("calculate", "assignmentBalances");
    params.append("populate", "brand_id");
    try {
      const inviteCreatorRequest = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(creator),
      });
      const inviteCreatorResponse = await fetch(inviteCreatorRequest);
      if (!inviteCreatorResponse) {
        throw new Error();
      }
      if (inviteCreatorResponse.status === 409) {
        throw new Error("Creator already exists");
      }
      if (inviteCreatorResponse.status !== 201) {
        throw new Error();
      }
      const inviteCreatorJson = await inviteCreatorResponse.json();
      dispatch(
        actionCreator(
          creatorsActionTypes.inviteCreator.SUCCESS,
          inviteCreatorJson,
        ),
      );
      showToast({ message: INVITE_CREATOR_SUCCESS, type: "success" });
      return Promise.resolve(inviteCreatorJson.result);
    } catch (error) {
      const message = error?.message
        ? `${INVITE_CREATOR_ERROR}: ${error.message}`
        : INVITE_CREATOR_ERROR;
      dispatch(
        actionCreator(creatorsActionTypes.inviteCreator.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const getCreator = (creator_id) => {
  return async(dispatch) => {
    dispatch(actionCreator(creatorsActionTypes.getCreator.REQUEST));
    try {
      let url = new URL(READ_ONE_CREATOR_URL);
      const params = url.searchParams;
      params.append("_id", creator_id);
      params.append(
        "calculate",
        "earnings availableBalance contentCounts canDeleteUser",
      );
      url = new URL(url);
      const creatorRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const creators = await fetch(creatorRequest);
      if (!creators || !creators.ok || creators.status !== 200) {
        throw new Error(GET_CREATOR_ERROR);
      } else {
        const creatorsJson = await creators.json();
        dispatch(
          actionCreator(creatorsActionTypes.getCreator.SUCCESS, creatorsJson),
        );
      }
    } catch (error) {
      dispatch(
        actionCreator(
          creatorsActionTypes.getCreator.FAILURE,
          error?.message || GET_CREATOR_ERROR,
        ),
      );
    }
  };
};

export const getCreators = () => {
  return async(dispatch) => {
    dispatch(actionCreator(creatorsActionTypes.getCreators.REQUEST));
    try {
      const url = new URL(READ_MANY_CREATORS_URL);
      const params = url.searchParams;
      params.append("skip", "0");
      params.append("limit", "0");
      params.append("calculate", "earnings availableBalance");
      const creatorsRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const creators = await fetch(creatorsRequest);
      if (!creators || !creators.ok || creators.status !== 200) {
        throw new Error(GET_CREATORS_ERROR);
      } else {
        const creatorsJson = await creators.json();
        dispatch(
          actionCreator(creatorsActionTypes.getCreators.SUCCESS, creatorsJson),
        );
      }
    } catch (error) {
      dispatch(
        actionCreator(
          creatorsActionTypes.getCreators.FAILURE,
          error?.message || GET_CREATORS_ERROR,
        ),
      );
    }
  };
};

export const getCreatorCampaignAssignments = (creator_id) => {
  return async(dispatch) => {
    dispatch(
      actionCreator(creatorsActionTypes.getCreatorCampaignAssignments.REQUEST),
    );
    try {
      const url = new URL(READ_MANY_CREATOR_CAMPAIGN_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.append("skip", "0");
      params.append("limit", "0");
      params.append("user_id", creator_id);
      params.append(
        "calculate",
        "contentCounts deliverableMetrics agreementMetrics",
      );
      params.append("populate", "campaign_id");
      // url = url.toString();
      const assignmentsRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const assignments = await fetch(assignmentsRequest);
      if (!assignments || !assignments.ok || assignments.status !== 200) {
        throw new Error(GET_CREATOR_ASSIGNMENTS_ERROR);
      } else {
        const creatorsJson = await assignments.json();
        dispatch(
          actionCreator(
            creatorsActionTypes.getCreatorCampaignAssignments.SUCCESS,
            creatorsJson,
          ),
        );
        return Promise.resolve(creatorsJson);
      }
    } catch (error) {
      dispatch(
        actionCreator(
          creatorsActionTypes.getCreatorCampaignAssignments.FAILURE,
          error?.message || GET_CREATORS_ERROR,
        ),
      );
      return Promise.reject(error?.message || GET_CREATOR_ASSIGNMENTS_ERROR);
    }
  };
};
export const getCreatorCampaignAssignment = (assignment_id) => {
  return async(dispatch) => {
    dispatch(
      actionCreator(creatorsActionTypes.getCreatorCampaignAssignment.REQUEST),
    );
    try {
      const url = new URL(READ_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL);
      const params = url.searchParams;
      params.append("skip", "0");
      params.append("limit", "0");
      params.append("_id", assignment_id);
      params.append(
        "calculate",
        "contentCounts deliverableMetrics agreementMetrics",
      );
      params.append("populate", "campaign_id");
      // url = url.toString();
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const response = await fetch(request);
      if (!response || !response.ok || response.status !== 200) {
        throw new Error(GET_CREATOR_ASSIGNMENTS_ERROR);
      } else {
        const result = await response.json();
        dispatch(
          actionCreator(
            creatorsActionTypes.getCreatorCampaignAssignment.SUCCESS,
            result,
          ),
        );
        return Promise.resolve(result);
      }
    } catch (error) {
      dispatch(
        actionCreator(
          creatorsActionTypes.getCreatorCampaignAssignment.FAILURE,
          error?.message || GET_CREATORS_ERROR,
        ),
      );
      return Promise.reject(error?.message || GET_CREATOR_ASSIGNMENTS_ERROR);
    }
  };
};

export const createCreatorCampaignAssignment = (assignment) => {
  return async(dispatch) => {
    dispatch(
      actionCreator(
        creatorsActionTypes.createCreatorCampaignAssignment.REQUEST,
      ),
    );
    try {
      assignment.populate = "campaign_id";
      const createAssignmentRequest = new Request(
        CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
        {
          method: "POST",
          credentials: "include",
          headers: privateHeaders(),
          body: JSON.stringify(assignment),
        },
      );
      const createAssignmentResponse = await fetch(createAssignmentRequest);
      if (
        !createAssignmentResponse
        || createAssignmentResponse.status !== 200
      ) {
        throw new Error();
      } else {
        const assignmentJson = await createAssignmentResponse.json();
        dispatch(
          actionCreator(
            creatorsActionTypes.createCreatorCampaignAssignment.SUCCESS,
            assignmentJson,
          ),
        );
        showToast({
          message: CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS,
          type: "success",
        });
        dispatch(getCreator(assignmentJson?.result?.user_id?._id));
        return Promise.resolve(assignmentJson?.result);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CREATOR_ASSIGNMENT_ERROR}: ${error.message}`
        : SAVE_CREATOR_ASSIGNMENT_ERROR;
      dispatch(
        actionCreator(
          creatorsActionTypes.createCreatorCampaignAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const removeCreatorCampaignAssignment = (assignment) => {
  return async(dispatch) => {
    dispatch(
      actionCreator(
        creatorsActionTypes.removeCreatorCampaignAssignment.REQUEST,
      ),
    );
    // prompt for confirmation
    const campaignName
      = assignment?.campaign_id?.name || assignment?.campaignName;
    const confirmPrompt = campaignName
      ? `Are you sure you want to remove this creator from campaign ${campaignName}?`
      : "Are you sure you want to remove this creator from this campaign?";
    if (!window.confirm(confirmPrompt)) {
      dispatch(
        actionCreator(
          creatorsActionTypes.removeCreatorCampaignAssignment.FAILURE,
        ),
      );
      return Promise.resolve();
    }
    try {
      if (!assignment?._id) throw new Error("No assignment._id to remove");
      const user_id = assignment?.user_id?._id || assignment?.user_id;
      if (!user_id) throw new Error("user_id not found on assignment");
      const deleteAssignmentRequest = new Request(
        DELETE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL.replace(
          ":_id",
          assignment?._id,
        ),
        {
          method: "DELETE",
          credentials: "include",
          headers: privateHeaders(),
        },
      );
      const response = await fetch(deleteAssignmentRequest);
      if (!response?.ok) {
        throw new Error();
      } else {
        dispatch(
          actionCreator(
            creatorsActionTypes.removeCreatorCampaignAssignment.SUCCESS,
          ),
        );
        showToast({
          message: REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS,
          type: "success",
        });
        dispatch(changeDisplayedCreatorAssignment(null));
        dispatch(getCreator(user_id));
        dispatch(getCreatorCampaignAssignments(user_id));
        return Promise.resolve();
      }
    } catch (error) {
      const message = error?.message
        ? `${REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_ERROR}: ${error.message}`
        : REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_ERROR;
      dispatch(
        actionCreator(
          creatorsActionTypes.removeCreatorCampaignAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const updateCreatorAssignment = (assignment) => {
  return async(dispatch) => {
    dispatch(
      actionCreator(creatorsActionTypes.updateCreatorAssignment.REQUEST),
    );
    try {
      if (!assignment?._id) throw new Error("No assignment._id to update");
      assignment.populate = "campaign_id";
      const updateAssignmentRequest = new Request(
        UPDATE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL,
        {
          method: "PUT",
          credentials: "include",
          headers: privateHeaders(),
          body: JSON.stringify(assignment),
        },
      );
      const updateAssignmentResponse = await fetch(updateAssignmentRequest);
      if (
        !updateAssignmentResponse
        || updateAssignmentResponse.status !== 200
      ) {
        throw new Error();
      } else {
        const assignmentJson = await updateAssignmentResponse.json();
        dispatch(
          actionCreator(
            creatorsActionTypes.updateCreatorAssignment.SUCCESS,
            assignmentJson,
          ),
        );
        showToast({
          message: SAVE_CREATOR_ASSIGNMENT_SUCCESS,
          type: "success",
        });
        return Promise.resolve(assignmentJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CREATOR_ASSIGNMENT_ERROR}: ${error.message}`
        : SAVE_CREATOR_ASSIGNMENT_ERROR;
      dispatch(
        actionCreator(
          creatorsActionTypes.updateCreatorAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const updateCreator = (creator) => {
  return async(dispatch) => {
    dispatch(actionCreator(creatorsActionTypes.updateCreator.REQUEST));
    try {
      if (!creator?._id) throw new Error("No creator id to update");
      creator.calculate
        = "earnings availableBalance contentCounts canDeleteUser";
      creator.populate = "campaign_id";
      const updateCreatorRequest = new Request(UPDATE_ONE_CREATOR_URL, {
        method: "PUT",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(creator),
      });
      const updateCreatorResponse = await fetch(updateCreatorRequest);
      if (!updateCreatorResponse || updateCreatorResponse.status !== 200) {
        throw new Error();
      } else {
        const createCampaignJson = await updateCreatorResponse.json();
        dispatch(
          actionCreator(
            creatorsActionTypes.updateCreator.SUCCESS,
            createCampaignJson,
          ),
        );
        showToast({ message: SAVE_CREATOR_SUCCESS, type: "success" });
        return Promise.resolve(createCampaignJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CREATOR_ERROR}: ${error.message}`
        : SAVE_CREATOR_ERROR;
      dispatch(
        actionCreator(creatorsActionTypes.updateCreator.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const changeDisplayedCreator = (creator) => {
  return (dispatch) => {
    dispatch(
      actionCreator(
        creatorsActionTypes.changeDisplayedCreator.SUCCESS,
        creator,
      ),
    );
    if (!creator) {
      dispatch(
        actionCreator(
          creatorsActionTypes.changeDisplayedCreatorAssignment.SUCCESS,
          null,
        ),
      );
    }
  };
};

export const changeDisplayedCreatorAssignment = (assignment) => {
  return (dispatch) => {
    dispatch(
      actionCreator(
        creatorsActionTypes.changeDisplayedCreatorAssignment.SUCCESS,
        assignment,
      ),
    );
  };
};

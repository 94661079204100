import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Button } from "reactstrap";

/**
 * Renders a link (usually used for main record links such as to a Brand, Campaign, etc).
 *
 * @param {object} props
 * @param {string} props.to
 * @param {string} props.text - Text to display in the link.
 * @param {boolean } [props.muted] - Whether the text should be muted.
 *
 * @returns {JSX.Element} The rendered BrandLink component.
 */
export const LinkCellRenderer = ({ to, text, muted }) => {
  const linkClassName = clsx(
    "ag-cell-value p-0 w-100 text-start text-decoration-none",
    {
      "small text-muted": muted,
      "link-accent-dark": !muted,
    },
  );

  return (
    <Button tag={NavLink} color="link" className={linkClassName} to={to}>
      {text}
    </Button>
  );
};

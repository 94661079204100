import { useCallback } from "react";

import { connect } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { getChangedValues } from "helpers/getChangedValues";
import { createMilestone, updateMilestone } from "modules/Campaigns/actions";

import { mileStoneFields } from "./constants";

const MilestoneModal = ({
  isOpen,
  onClosed,
  toggle,
  milestone = undefined,
  assignment,
  loading,
  createMilestone,
  updateMilestone,
  ...props
}) => {
  // TODO: fix formik context
  // const { dirty, resetForm } = useFormikContext();

  const toggleModal = useCallback(() => {
    // if (isOpen && dirty) {
    //   const confirm = window.confirm(
    //     "You have unsaved changes. Are you sure you want to leave?",
    //   );
    //   if (confirm) {
    //     toggle();
    //   }
    //   return;
    // }
    toggle();
  }, [toggle]);

  const closedModal = useCallback(() => {
    // resetForm();
    onClosed();
  }, [onClosed]);

  const initialValues = getInitialValues(mileStoneFields, milestone);

  const validate = useCallback(
    (values) => {
      const errors = {};
      const changedValues = getChangedValues(values, initialValues);
      mileStoneFields?.forEach((field) => {
        if (field?.editing?.required && !values[field?.fieldKey]) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (
          field?.type === "number"
          && (!values[field?.fieldKey] || values[field?.fieldKey] <= 0)
        ) {
          errors[field?.fieldKey] = `Must be an amount greater than 0`;
        }

        if (
          field?.fieldKey === "updateAssignmentRate"
          || field?.fieldKey === "rate"
        ) {
          // make sure total of all milestones does not exceed assignment rate/budget
          const milestonesBalance = assignment?.milestones?.reduce(
            (acc, milestone) => {
              if (milestone._id === values?._id) return acc;
              return acc + milestone.rate;
            },
            0,
          );
          const remainingBalance = assignment?.rate - milestonesBalance;
          if (
            remainingBalance < (values.rate || 0)
            && !values.updateAssignmentRate
          ) {
            errors[field?.fieldKey]
              = `Milestone would exceed remaining assignment rate.`;
          }
        }

        if (field.fieldKey === "rate") {
          if (Object.keys(changedValues).includes("rate")) {
            if (values?.isComplete) {
              // if within grace period, rate can decrease but not increase
              if (checkGracePeriod(values?.dateCompleted)) {
                if (values?.rate > initialValues?.rate) {
                  errors[field?.fieldKey]
                    = "Milestone rate can not be increased after it has been completed.";
                }
              } else {
                // if outside grace period, rate can not change in either direction
                if (values?.rate !== initialValues?.rate) {
                  errors[field?.fieldKey]
                    = "Milestone rate can not be changed after it has been completed and the grace period has passed.";
                }
              }
            }
            if (values?.releasePayment) {
              // can not change rate if payment has been released
              errors[field?.fieldKey]
                = "Milestone rate can not be changed when it has been released for payment.";
            }
          }
        }

        if (
          field.fieldKey === "isComplete"
          && Object.keys(changedValues).includes("isComplete")
          && !values?.isComplete
          && initialValues?.isComplete
        ) {
          // can not un-complete a milestone outside of grace period
          if (!checkGracePeriod(initialValues?.dateCompleted)) {
            errors[field?.fieldKey]
              = "Milestones can only be un-completed within 1 calendar month of completion date.";
          }
        }

        if (field?.fieldKey === "dateCompleted") {
          if (!values.dateCompleted && values.isComplete) {
            errors[field?.fieldKey] = `Completion date is required`;
          }

          if (changedValues?.dateCompleted) {
            const dateCompleted = new Date(values.dateCompleted);
            const today = new Date();
            // must not be in the future
            if (dateCompleted > today) {
              errors[field?.fieldKey]
                = "Completion date cannot be in the future.";
            } else {
              // date completed must be within grace period
              if (!checkGracePeriod(values.dateCompleted)) {
                errors[field?.fieldKey]
                  = "Completion date must be within 1 calendar month of today.";
              }
            }
          }
        }

        if (
          field.fieldKey === "releasePayment"
          && Object.keys(changedValues).includes("releasePayment")
          && values?.releasePayment
          && !values?.isComplete
        ) {
          // can not release for payment if not complete
          errors[field?.fieldKey]
            = "Milestones can only be released for payment after they are complete.";
        }
      });

      return errors;
    },
    [assignment?.milestones, assignment?.rate, initialValues],
  );

  const submit = useCallback(
    async(values, actions) => {
      if (milestone?._id) {
        // update
        const changedValues = getChangedValues(values, initialValues);
        if (!changedValues?.isComplete && !initialValues?.isComplete) {
          // do not update dateCompleted if milestone is not complete or being completed
          delete changedValues?.dateCompleted;
        }
        if (Object.keys(changedValues).length) {
          await updateMilestone({
            milestone: {
              _id: milestone?._id,
              assignment_id: milestone?.assignment_id,
              ...changedValues,
            },
            assignment_id: assignment?._id,
          });
        }
      } else {
        // create
        await createMilestone({ ...values, assignment_id: assignment?._id });
      }
      actions.setSubmitting(false);
      toggleModal();
    },
    [
      milestone?._id,
      milestone?.assignment_id,
      toggleModal,
      initialValues,
      updateMilestone,
      assignment?._id,
      createMilestone,
    ],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClosed={closedModal}
      onExit={closedModal}
      toggle={toggleModal}
      unmountOnClose={true}
      className="milestone-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validate={validate}
        enableReinitialize={true}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <UnsavedChangesPrompt />
            <ModalHeader toggle={toggleModal}>
              {milestone?._id ? "Edit Milestone" : "New Milestone"}
            </ModalHeader>
            <ModalBody>
              {loading && <Loader />}
              {/* <div className="labeled-data-container"> */}
              {mileStoneFields?.map((def, i) => {
                return (
                  <LabelledData
                    key={i}
                    definition={def}
                    isEditing={true}
                    data={milestone || {}}
                    hideConditionCompareValues={assignment}
                    disableConditionCompareValues={initialValues}
                  />
                );
              })}
              {/* </div> */}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button color="dark" type="submit">
                {milestone ? "Save" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

function checkGracePeriod(dateString) {
  const compareDate = new Date(dateString);
  const today = new Date();
  // grace period is within the same calendar month, unless it's currently within the first 5 days of the month, in which case the grace period also includes the previous calendar month
  const isGracePeriod
    = compareDate.getMonth() === today.getMonth()
    || (today.getDate() <= 5 && compareDate.getMonth() === today.getMonth() - 1);
  return isGracePeriod;
}

const mapStateToProps = (state) => {
  return {
    loading:
      state.loading.CREATE_MILESTONE?.pending
      || state.loading.UPDATE_MILESTONE?.pending,
  };
};
export default connect(mapStateToProps, { createMilestone, updateMilestone })(
  MilestoneModal,
);

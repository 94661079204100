/**
 * ! @deprecated This component is deprecated, use native back navigation instead.
 * @param {Object} props - The props object.
 * @param {string} props.to - The destination URL for the back navigation.
 */
const BackNav = ({ to, ...props }) => {
  return null;
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   navigate(-1);
  // };
  // return (
  //   <div className="back-nav" {...props}>
  //     {to ? (
  //       <Button color="link" tag={Link} to={to} className="text-muted p-0">
  //         <ArrowLeftIcon height={20} width={20} />
  //         {/* Back */}
  //       </Button>
  //     ) : (
  //       <Button color="link" onClick={handleClick} className="p-0">
  //         <ArrowLeftIcon height={20} width={20} />
  //         {/* Back */}
  //       </Button>
  //     )}
  //   </div>
  // );
};

export default BackNav;

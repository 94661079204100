import { useMemo } from "react";

import clsx from "clsx";

import { TransfersGrid } from "features/transfers/components/TransfersGrid";

export const BrandTransfers = ({ brand_id, className, ...props }) => {
  const classes = clsx("brand-transfers", className);
  const filterParams = useMemo(() => {
    return {
      brand_id,
    };
  }, [brand_id]);
  return (
    <TransfersGrid
      filterParams={filterParams}
      className={classes}
      gridId="brand-transfers"
      excludeDataSources="brand"
    />
  );
};

import { Col, Row } from "reactstrap";

import "./index.scss";
import Balances from "./components/Balances";
import MilestonesCard from "./components/MilestonesCard";

const Dashboard = () => {
  return (
    <section className="dashboard main-page">
      <div className="dashboard__content main-page__content">
        <div className="main-page__body">
          <Row className="g-4">
            <Balances />
            <Col md={12}>
              <MilestonesCard />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;

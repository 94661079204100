import { memo } from "react";

import { CampaignLinkCellRenderer } from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

export const campaignColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "_id",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    filterValueGetter: (params) => {
      if (!params.data?.name) {
        return "";
      }
      return `${params.data?.name} ${params.data?._id}`;
    },
  },
  {
    headerName: "Short ID",
    field: "_id",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "shortId",
    },
    filterValueGetter: (params) => {
      if (!params.data?.shortId) {
        return "";
      }
      return `${params.data?.shortId} ${params.data?._id}`;
    },
  },
  {
    headerName: "Status",
    field: "status",
    type: "text",
    cellRenderer: memo(CampaignStatusBadge),
  },
  {
    headerName: "Department",
    field: "department",
    type: "text",
    valueGetter: (params) => {
      let text = "";
      if (params.data?.department === "managed") {
        text = "Managed Services";
      }
      if (params.data?.department === "talent") {
        text = "Talent";
      }
      return text;
    },
  },
  {
    headerName: "Account Segmentation",
    field: "enableAccountSegmentation",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Brand",
    field: "brand_id._id",
    dataSource: "brand",
    cellRenderer: memo(BrandLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.brand_id?.name) {
        return "";
      }
      return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
    },
  },
  {
    headerName: "Invoiced",
    field: "invoicedAmount",
    type: "currency",
  },
  {
    headerName: "Funded",
    field: "fundedAmount",
    type: "currency",
  },
  {
    headerName: "Allocated",
    field: "__assignmentBalances.allocatedBalance",
    type: "currency",
  },
  {
    headerName: "Unallocated",
    field: "__assignmentBalances.unallocatedBalance",
    type: "currency",
  },
  {
    headerName: "Budget",
    field: "creatorBudget",
    type: "currency",
  },
  {
    headerName: "Paid to Creators",
    field: "amountPaidToCreators",
    type: "currency",
  },
  {
    headerName: "Hold",
    field: "__assignmentBalances.holdBalance",
    type: "currency",
  },
  {
    headerName: "Creators",
    field: "__assignmentBalances.count",
    type: "count",
  },
  {
    headerName: "Client Login Allowed",
    field: "allowClientAccess",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Start Date",
    field: "startDate",
    type: "date",
  },
  {
    headerName: "End Date",
    field: "endDate",
    type: "date",
  },
  {
    headerName: "Created Date",
    field: "created",
    type: "date",
    sort: "desc",
  },
];

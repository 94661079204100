import { useParams } from "react-router-dom";

import { brandTabOptions } from "features/brands/constants/tabOptions";

export const BrandAssignmentSection = () => {
  const { assignmentsTab } = useParams();
  const Subview = brandTabOptions.find(
    (tab) => tab.id === assignmentsTab,
  )?.Subview;
  if (!Subview) {
    return;
  }

  return (
    // TODO @segmentation error boundary
    <div className="brand-assignment-section flex-fill">
      <Subview />
    </div>
  );
};

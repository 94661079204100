import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";

import { apiSlice } from "app/apiSlice";
import Loader from "components/Loader";
import { currencyFormatter } from "helpers/formatters";

const CampaignsBalanceCard = () => {
  const { data, isLoading } = apiSlice.useGetCampaignBalancesQuery();
  const campaignBalances = data?.result;
  return (
    <Card>
      <CardBody>
        {isLoading && <Loader />}
        <CardTitle>
          <h2 className="fs-4">Segmented Campaign Balances</h2>
        </CardTitle>
        <CardText className="dashboard__content__labelled-data">
          <span>
            <span className="small text-muted mb-2">Invoiced</span>
            {currencyFormatter(campaignBalances?.invoicedAmount)}
          </span>
          <span>
            <span className="small text-muted mb-2">Funded</span>
            {currencyFormatter(campaignBalances?.fundedAmount)}
          </span>
          <span>
            <span className="small text-muted mb-2">Allocated</span>
            {currencyFormatter(campaignBalances?.allocatedBalance)}
          </span>
          <span>
            <span className="small text-muted mb-2">Unallocated</span>
            {currencyFormatter(campaignBalances?.unallocatedBalance)}
          </span>
          <span>
            <span className="small text-muted mb-2">Creator Budget</span>
            {currencyFormatter(campaignBalances?.creatorBudget)}
          </span>
          <span>
            <span className="small text-muted mb-2">Paid to Creators</span>
            {currencyFormatter(campaignBalances?.amountPaidToCreators)}
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default CampaignsBalanceCard;

import { Button } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/24/outline";
import "./index.scss";

const CloseNav = ({ onClick, ...props }) => {
  return (
    <div className="close-nav" {...props}>
      <Button color="link" className="text-muted p-0" onClick={onClick}>
        <XMarkIcon height={20} width={20} />
        {/* Close */}
      </Button>
    </div>
  );
};

export default CloseNav;

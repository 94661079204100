import LabelledData from "components/Forms/LabelledData";
import useUnsavedChangesPrompt from "components/Forms/hooks/useUnsavedChangesPrompt";

/**
 * @typedef {Object} CreateCampaignFieldsProps
 * @property {Object[]} fieldDefs - The definitions of the fields.
 * @property {Object} initialValues - The initial values of the fields.
 */
/**
 * Create Campaign Fields
 * @param {CreateCampaignFieldsProps} props
 * @returns {JSX.Element}
 */
export const CreateCampaignFields = ({
  fieldDefs,
  initialValues,
  ...props
}) => {
  useUnsavedChangesPrompt();
  return (
    <>
      {fieldDefs?.map((fieldDef, i) => {
        if (fieldDef?.role === "section") {
          return (
            <div key={i} className="labeled-data-container">
              {fieldDef?.fields?.map((subDef, j) => {
                return (
                  <LabelledData
                    key={`${fieldDef?.fieldKey}-${j}`}
                    data={initialValues}
                    definition={subDef}
                    isEditing={true}
                    editingOnly={true}
                  />
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};
